import React, { useState } from 'react'

import {
    invalidateDataConnections,
    useDataConnections,
} from 'data/hooks/dataConnections/useDataConnections'
import { useDeleteDataConnection } from 'data/hooks/dataConnections/useDeleteDataConnection'

import { Icon, LoadingScreen } from 'v2/ui'
import { Airtable } from 'v2/ui/svgs'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body, Headline } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'

import { NewDataConnectionsModal } from './NewDataConnectionsModal'

const DC_TYPE_TO_INTEGRATION_NAME = {
    nango_airtable: 'Airtable',
}
type DataConnectionRowProps = {
    dataConnection: DataConnectionDto
    onDeleteDataConnection: () => Promise<void>
}

const DataConnectionRow: React.FC<DataConnectionRowProps> = ({
    dataConnection,
    onDeleteDataConnection,
}) => {
    // TODO-Nango continue UI as part of T2529
    if (dataConnection.type == 'native_tables') return null

    return (
        <Box flex justifyContent="space-between">
            <Box flex>
                <Icon height="35px" width="35px" style={{}} svg={<Airtable />} />
                <Box flex column>
                    <Body size="m" weight="medium">
                        {DC_TYPE_TO_INTEGRATION_NAME[dataConnection.type]}
                    </Body>
                    <Body size="s">{dataConnection.external_user_email}</Body>
                </Box>
            </Box>
            <Box flex>
                {/*
                    last synced
                    settings
                 */}
            </Box>
            <>
                <Button
                    variant="secondary"
                    size="m"
                    startIcon={{ name: 'Trash' }}
                    ml="2xl"
                    onClick={onDeleteDataConnection}
                >
                    Delete
                </Button>
            </>
        </Box>
    )
}

export const DataConnectionsManager: React.FC = () => {
    const toast = useToast()
    const [isNewDataConnectionModalOpen, setIsNewDataConnectionModalOpen] = useState(false)

    const { data: dataConnections, isLoading } = useDataConnections()
    const { mutateAsync: deleteDataConnection } = useDeleteDataConnection({
        onError: () => {
            toast({
                title: 'There was a problem deleting the data connection. Please try again later.',
                type: 'error',
            })
        },
    })

    return (
        <>
            <Box
                flex
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                pt="2xl"
            >
                <LoadingScreen isLoading={isLoading}>
                    <Box flex column stretch style={{ margin: '30px 20px' }}>
                        <Box flex justifyContent="space-between">
                            <Headline size="s">Data sources</Headline>

                            <Button
                                type="button"
                                startIcon={{ name: 'Plus' }}
                                size="s"
                                // TODO-Nango: Add new modal that takes user to data source selector first (or maybe that should be part
                                //   of the logic of NewDataConnectionsModal - if integrationId is provider use that, otherwise show
                                //   screen to choose integration)
                                onClick={() => setIsNewDataConnectionModalOpen(true)}
                            >
                                Add data source
                            </Button>
                        </Box>
                        <Box style={{ marginTop: '20px' }}>
                            {dataConnections?.map((dataConnection) => (
                                <DataConnectionRow
                                    key={dataConnection._sid}
                                    dataConnection={dataConnection}
                                    onDeleteDataConnection={async () => {
                                        await deleteDataConnection(dataConnection._sid)
                                        invalidateDataConnections()
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </LoadingScreen>
            </Box>
            <NewDataConnectionsModal
                isOpen={isNewDataConnectionModalOpen}
                toggle={() => setIsNewDataConnectionModalOpen(!isNewDataConnectionModalOpen)}
                onClose={() => setIsNewDataConnectionModalOpen(false)}
            />
        </>
    )
}
