import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Redirect, useHistory } from 'react-router-dom'

import QueryString from 'qs'
import { sendPasswordResetEmail } from 'supertokens-auth-react/recipe/emailpassword'
import { submitNewPassword } from 'supertokens-web-js/recipe/emailpassword'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent } from 'ui/components/Collapsible'
import { Input } from 'ui/components/Input'
import { Link } from 'ui/components/Link'

import { AuthFrame } from './AuthFrame'

type LoginFormData = {
    email: string
    password: string
}
export function ResetPasswordPage() {
    const { user } = useAuthContext()
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })

    if (user) {
        return <Redirect to={getUrl(Urls.Root)} />
    }

    return (
        <AuthFrame title="Reset password">
            {query.token ? <SetNewPasswordForm /> : <RequestPasswordResetForm />}
        </AuthFrame>
    )
}

function RequestPasswordResetForm() {
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: { email: query.email?.toString() ?? '' },
    })

    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)

    async function onSubmit({ email }: LoginFormData) {
        try {
            setIsLoading(true)
            const response = await sendPasswordResetEmail({
                formFields: [
                    {
                        id: 'email',
                        value: email,
                    },
                ],
            })
            if (response.status !== 'OK') {
                setErrorMessage('An error occurred. Please try again.')
            } else {
                setEmailSent(true)
            }
        } catch (ex) {
            console.error(ex)
            setErrorMessage('An error occurred. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box as="form" flex column gap="m" onSubmit={handleSubmit(onSubmit)}>
            <Input
                placeholder="Email address"
                {...register('email', { required: true })}
                isError={errors?.email}
                helperText={errors?.email && 'Email is required'}
                autoFocus
            />

            {errorMessage && (
                <Box color="textError" fontSize="bodyM" alignSelf="center" mb="m">
                    {errorMessage}
                </Box>
            )}
            <Button variant="primary" mt="m" type="submit" isLoading={isLoading}>
                Request password reset
            </Button>

            <Link size="s" to={getUrl(Urls.Login)} alignSelf="center">
                Back to log in
            </Link>
            <Banner
                show={emailSent}
                icon={{ name: 'Mail' }}
                title="Check your email"
                helper="We've sent you an email with a link to reset your password."
            />
        </Box>
    )
}
function SetNewPasswordForm() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    })

    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const history = useHistory()

    async function onSubmit({ password }: LoginFormData) {
        try {
            setIsLoading(true)
            const response = await submitNewPassword({
                formFields: [
                    {
                        id: 'password',
                        value: password,
                    },
                ],
            })

            if (response.status !== 'OK') {
                setErrorMessage('An error occurred. Please try again.')
            } else {
                setShowSuccess(true)
            }
        } catch (ex) {
            console.error(ex)
            setErrorMessage('An error occurred. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Box as="form" flex column gap="m" onSubmit={handleSubmit(onSubmit)}>
            <Collapsible open={!showSuccess}>
                <CollapsibleContent>
                    <Box flex column gap="m" p="s">
                        <Input
                            placeholder="New password"
                            type="password"
                            {...register('password', { required: true, minLength: 8 })}
                            isError={errors?.password}
                            helperText={
                                errors?.password?.type === 'required'
                                    ? 'Password is required'
                                    : errors?.password?.type === 'minLength'
                                      ? 'Must be at least 8 characters'
                                      : ''
                            }
                        />
                        {errorMessage && (
                            <Box color="textError" fontSize="bodyM" alignSelf="center" mb="m">
                                {errorMessage}
                            </Box>
                        )}
                        <Button variant="primary" mt="m" type="submit" isLoading={isLoading}>
                            Set password
                        </Button>
                    </Box>
                </CollapsibleContent>
            </Collapsible>
            <Banner
                show={showSuccess}
                type="success"
                icon={{ name: 'Check' }}
                title="Password reset"
                helper="Login with your new password."
                button={{
                    children: 'Log in',
                    onClick: () => history.push(getUrl(Urls.Login)),
                }}
            />
        </Box>
    )
}
