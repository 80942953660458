import React from 'react'

import { useMainNavigationState } from './hooks/useMainNavigationState'
import { AppNavigation } from './AppNavigation'
import * as Parts from './Navigation.parts'
import { PortalNavigation } from './PortalNavigation'

type MainNavigationProps = {}

export const MainNavigation: React.FC<MainNavigationProps> = () => {
    const { isNavCollapsed, isPortal } = useMainNavigationState()

    if (isNavCollapsed) return null

    return (
        <Parts.MainNavigationWrapper>
            {isPortal ? <PortalNavigation /> : <AppNavigation />}
        </Parts.MainNavigationWrapper>
    )
}
