import React, { useCallback, useMemo, useState } from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { DropdownEmptySearch as EmptySearch } from 'ui/components/Dropdown/DropdownEmptySearch'
import { DropdownHeadBase } from 'ui/components/Dropdown/DropdownHeadBase'
import { useKeyDownFromHead } from 'ui/components/Dropdown/useKeyDownFromHead'
import { Icon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import {
    HeadSearchInputStyle,
    HeadSearchWrapperStyle,
    NoItemsFoundStyle,
} from 'ui/components/Menu/Menu.css'
import { Select, SelectOption } from 'ui/components/Select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui/components/Tabs'
import { Body } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'

import {
    FieldValue,
    Option as FieldInputOption,
    useListViewControlFieldInputState,
} from './hooks/useListViewControlFieldInputState'

import {
    ListViewControlsFieldInputSelectStyle,
    ListViewControlsFieldInputTabListStyle,
} from './ListView.css'

type ListViewControlFieldInputProps = React.ComponentPropsWithoutRef<typeof Input> & {
    objectSid: string
    value?: FieldValue
    displayOptions?: ListViewColumnConfigDisplayOptions
    onChange: (value?: FieldValue) => void
    onDisplayOptionsChange: (value: ListViewColumnConfigDisplayOptions) => void
    fields: FieldDto[]
    showActions?: boolean
}

export const ListViewControlFieldInput: React.FC<ListViewControlFieldInputProps> = ({
    objectSid,
    fields,
    value,
    displayOptions,
    onDisplayOptionsChange,
    showActions = true,
    ...props
}) => {
    const { selectedField, isUsersObjectLink, dynamicOptions, fieldOptions, actionOptions } =
        useListViewControlFieldInputState({
            fields,
            value,
            objectSid,
            showActions,
        })

    const selectedTab = value ? getFooterSelectionType(value) : 'fields'

    return (
        <Box className={ListViewControlsFieldInputSelectStyle}>
            <Select
                size="m"
                isClearable
                placeholder="Select field..."
                value={value}
                optionsOverride={[
                    ...dynamicOptions.map((option) => ({
                        ...option,
                        label: (
                            <Box key={option.value} flex flexDirection="row">
                                Dynamic <Icon name="ArrowRight" size="s" m="xs" />
                                {option.label}
                            </Box>
                        ),
                    })),
                    ...fieldOptions.map((option) => ({
                        ...option,
                        label: (
                            <Box key={option.value} flex flexDirection="row">
                                Fields <Icon name="ArrowRight" size="s" m="xs" />
                                {option.label}
                            </Box>
                        ),
                    })),
                    ...actionOptions.map((option) => ({
                        ...option,
                        label: (
                            <Box key={option.value} flex flexDirection="row">
                                Actions <Icon name="ArrowRight" size="s" m="xs" />
                                {option.label}
                            </Box>
                        ),
                    })),
                ]}
                contentProps={{
                    className: ListViewControlsFieldInputSelectStyle,
                }}
                {...props}
            >
                <Tabs flex column type="underlined" defaultValue={selectedTab}>
                    <TabsList
                        mb="m"
                        width="full"
                        position="sticky"
                        top={0}
                        className={ListViewControlsFieldInputTabListStyle}
                        background="surface"
                    >
                        <TabsTrigger value="fields" label="Fields" />
                        {showActions && <TabsTrigger value="actions" label="Actions" />}
                        <TabsTrigger value="dynamic" label="Dynamic" />
                    </TabsList>
                    <OptionsTabContent value="fields" options={fieldOptions} />
                    {showActions && <OptionsTabContent value="actions" options={actionOptions} />}
                    <OptionsTabContent value="dynamic" options={dynamicOptions} />
                </Tabs>
            </Select>
            {selectedField?.type === 'multi_file' && (
                <Box mt="m">
                    <Toggle
                        checked={displayOptions?.displayAsImage}
                        onCheckedChange={(isChecked) => {
                            onDisplayOptionsChange({ displayAsImage: isChecked })
                        }}
                    >
                        Display as image
                    </Toggle>
                </Box>
            )}
            {(isUsersObjectLink || selectedField?.type === 'user_ref') && (
                <Box mt="m">
                    <Toggle
                        checked={!displayOptions?.hideUserName}
                        onCheckedChange={(isChecked) => {
                            onDisplayOptionsChange({ hideUserName: !isChecked })
                        }}
                    >
                        Show user name
                    </Toggle>
                </Box>
            )}
        </Box>
    )
}

type OptionsTabContentProps = {
    value: string
    options: FieldInputOption[]
}

const OptionsTabContent: React.FC<OptionsTabContentProps> = ({ value, options }) => {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const trimmedSearchQuery = useMemo(() => searchQuery.trim(), [searchQuery])

    const filteredOptions = useMemo(() => {
        if (!trimmedSearchQuery) return options

        return options.filter((option) =>
            option.label.toLowerCase().includes(trimmedSearchQuery.toLowerCase())
        )
    }, [options, trimmedSearchQuery])

    if (options.length === 0) {
        return (
            <TabsContent value={value}>
                <EmptyOptions />
            </TabsContent>
        )
    }

    return (
        <TabsContent value={value}>
            <SearchInput
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                style={{
                    position: 'relative',
                }}
            />
            {filteredOptions.length === 0 ? (
                <EmptySearch />
            ) : (
                filteredOptions.map(({ label, value, icon }) => (
                    <SelectOption key={value} value={value} label={label} startIcon={icon} />
                ))
            )}
        </TabsContent>
    )
}

const EmptyOptions = () => {
    return (
        <Box className={NoItemsFoundStyle}>
            <Body size="m" weight="regular" color="textHelper" style={{ lineHeight: 1 }}>
                No options
            </Body>
        </Box>
    )
}

const getFooterSelectionType = (value: string) => {
    if (value.startsWith('action')) {
        return 'actions'
    } else if (value.startsWith('field')) {
        return 'fields'
    } else if (value.startsWith('_')) {
        return 'dynamic'
    }
}

type SearchInputRef = HTMLDivElement

type InheritedInputProps = Pick<
    React.ComponentPropsWithoutRef<'input'>,
    'placeholder' | 'autoFocus' | 'value' | 'onChange'
>

type SearchInputProps = React.ComponentPropsWithoutRef<typeof DropdownHeadBase> &
    InheritedInputProps & {
        searchQuery: string | undefined
        setSearchQuery: (value: string) => void
    }

export const SearchInput = React.forwardRef<SearchInputRef, SearchInputProps>(
    (
        {
            placeholder = 'Filter options…',
            onFocus,
            className,
            searchQuery,
            setSearchQuery,
            ...props
        },
        ref
    ) => {
        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(e.target.value)
            },
            [setSearchQuery]
        )

        const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)
        const [headRef, setHeadRef] = useState<HTMLElement | null>(null)

        const handleOnFocus = useCallback(
            (e: React.FocusEvent<HTMLDivElement>) => {
                e.preventDefault()
                e.stopPropagation()

                if (!searchQuery) return

                // Only run this when the head is focused from another menu item.
                if (e.target !== e.currentTarget) return

                const input = inputRef
                if (!input || !searchQuery) return

                input.focus()
                input.setSelectionRange(searchQuery.length, searchQuery.length)

                onFocus?.(e)
            },
            [inputRef, onFocus, searchQuery]
        )

        const handleInputKeyDown = useKeyDownFromHead(headRef)

        const handleOnSelect = useCallback(() => {
            inputRef?.focus()
        }, [inputRef])

        return (
            <DropdownHeadBase
                onFocus={handleOnFocus}
                onSelect={handleOnSelect}
                className={classNames(className, HeadSearchWrapperStyle)}
                {...props}
                ref={(element: HTMLDivElement) => {
                    setHeadRef(element)
                    return ref
                }}
            >
                <input
                    ref={setInputRef}
                    type="text"
                    className={HeadSearchInputStyle}
                    value={searchQuery}
                    onChange={handleChange}
                    placeholder={placeholder}
                    autoFocus={true}
                    onKeyDown={handleInputKeyDown}
                />
            </DropdownHeadBase>
        )
    }
)
