import React, { useState } from 'react'

import * as Portal from '@radix-ui/react-portal'

import useLDFlags from 'data/hooks/useLDFlags'
import { Navigation } from 'features/navigation/Navigation'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { Button, ButtonProps } from 'ui/components/Button'
import { breakpoints } from 'ui/styling/helpers/breakpoints'

import { WorkspaceDrawer } from './WorkspaceDrawer'

type NavMenuButtonProps = ButtonProps & {}
export function NavMenuButton({ ...props }: NavMenuButtonProps) {
    const isMobile = useIsMobile(breakpoints.tablet.end)
    const [isOpen, setIsOpen] = useState(false)
    const shouldShow = isMobile

    const { flags } = useLDFlags()

    if (!shouldShow) return null
    return (
        <>
            <Button
                startIcon={{ name: 'Menu' }}
                variant="ghost"
                {...props}
                onClick={() => setIsOpen((value) => !value)}
            />
            <Portal.Root>
                {flags.navigationV2 ? (
                    <Navigation />
                ) : (
                    <WorkspaceDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
                )}
            </Portal.Root>
        </>
    )
}
