import React from 'react'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

import { useSystemNavigationItemSearchState } from './hooks/useSystemNavigationItemSearchState'
import { SystemNavigationItem } from './SystemNavigationItem'

import {
    SystemNavigationItemSearchIconStyle,
    SystemNavigationItemSearchStyles,
} from './Navigation.css'

type SystemNavigationItemSearchProps = React.ComponentPropsWithoutRef<
    typeof SystemNavigationItem
> & {}

export const SystemNavigationItemSearch: React.FC<SystemNavigationItemSearchProps> = ({
    isCollapsed,
    ...props
}) => {
    const { onClick, keyboardShortcutLabel } = useSystemNavigationItemSearchState()

    return (
        <SystemNavigationItem
            aria-label="Search"
            iconName="search-01"
            label="Search"
            onClick={onClick}
            leftSlotContent={
                !isCollapsed && (
                    <Box noShrink flex center>
                        <Icon
                            name="search-01"
                            family="hugeicons"
                            color="icon"
                            size="m"
                            className={SystemNavigationItemSearchIconStyle}
                        />
                    </Box>
                )
            }
            rightSlotContent={
                !isCollapsed && (
                    <Body color="textWeakest" size="s" weight="medium">
                        {keyboardShortcutLabel}
                    </Body>
                )
            }
            className={SystemNavigationItemSearchStyles.styleFunction({
                isCollapsed,
            })}
            isCollapsed={isCollapsed}
            {...props}
        />
    )
}
