import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import type { Row } from 'react-table'

import * as Sentry from '@sentry/react'
import type { History, Location } from 'history'
import memoize from 'lodash/memoize'
import uniq from 'lodash/uniq'
import queryString from 'query-string'
import { ViewLayoutContext } from 'v2/blocks/types'
import { DetailView } from 'v2/views/Detail/DetailView/DetailView'
import useViewBreadcrumbTitle from 'v2/views/useViewBreadcrumbTitle'
import { getColumns } from 'v2/views/utils/getColumns'
import { getHiddenColumnsForUserFilter } from 'v2/views/utils/getHiddenColumnsForUserFilter'
import isArrayEqual from 'v2/views/utils/isArrayEqual'
import { ListViewOrderType } from 'v2/views/utils/orderBy/types'
import { useOrderByParams } from 'v2/views/utils/orderBy/useOrderByParams'
import useHistoryBreadcrumb from 'v2/views/utils/useHistoryBreadcrumb'

import { UnsavedChangesModal } from 'app/UnsavedChangesModal'
import { getUrl } from 'app/UrlService'
import { useAppContext } from 'app/useAppContext'
import { recordApi } from 'data/api/recordApi'
import { useActions } from 'data/hooks/actions'
import { useObject, useObjects } from 'data/hooks/objects'
import { useRecordCount } from 'data/hooks/records'
import { useUserRecord } from 'data/hooks/users/main'
import { useViews } from 'data/hooks/views'
import WithRecordsBase from 'data/wrappers/WithRecords'
import { getPendingRecordIds } from 'features/datagrid/hooks/getPendingRecordIds'
import { getFilterFields, processStaticFilter } from 'features/records/components/logic/recordLogic'
import { useLastFilters } from 'features/records/components/RecordFilters/inlineFilters/useLastFilters'
import { getOrderFields, processOrder } from 'features/records/components/RecordOrder'
import { RecordEditManagerContext } from 'features/records/RecordEditManagerContext'
import { RecordEditManagerContextProvider } from 'features/records/RecordEditManagerContextProvider'
import { currentUserOptions, userFieldOptions } from 'features/utils/filtersToQueryDict'
import { ListViewHeader } from 'features/views/ViewHeader/ListViewHeader'
import useTrack from 'utils/useTrack'

import { getKanbanFilters, useKanbanSettings } from 'v2/ui/components/kanbanUtils'
import { ListViewRecordContextMenuWrapper } from 'v2/ui/components/ListViewRecordContextMenuWrapper'
import LoadingOverlay from 'v2/ui/components/LoadingOverlay'
import { Toast } from 'v2/ui/components/Toast'
import { useDeepEqualsMemoValue } from 'v2/ui/utils/useDeepEqualsMemoValue'
import useDeepEqualsState from 'v2/ui/utils/useDeepEqualsState'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { PreviewRecordList } from './PreviewRecord/PreviewRecordContext'
import { usePreviewRecordContext } from './PreviewRecord/usePreviewRecordContext'
import { getColumnConfig } from './getColumnConfig'
import { getServerDefaultPageSize } from './getServerDefaultPageSize'
import ListViewEditMode from './ListViewEditMode'
import ListViewEditPane from './ListViewEditPane'
import ListViewInboxMode from './ListViewInboxMode'
import ListViewLoadingState from './ListViewLoadingState'
import ListViewObjectNotAccessible from './ListViewObjectNotAccessible'
import {
    CustomListRendererComponent,
    CustomLoadingState,
    OpenRecordFunction,
    ViewColumn,
} from './types'
import { ViewState } from './viewStateType'

const currentOptions = new Set(currentUserOptions.concat(userFieldOptions))

const getFilteredOrderedRecords = memoize(
    (
        records: RecordDto[],
        filters: Filter[],
        relatedListRecord?: RecordDto,
        limitRows?: boolean,
        orderBy?: { id: string; desc?: boolean },
        orderType?: ListViewOrderType
    ) => {
        let filteredRecords = processStaticFilter(records, filters, relatedListRecord)
        const orderByOptions: Record<string, unknown>[] = []

        if (orderType === 'manual') {
            orderByOptions.push({ id: '_local_display_order', nullsAlwaysLast: true })
        }
        if (orderBy) {
            orderByOptions.push(orderBy)
        }

        if (orderByOptions.length > 0) {
            filteredRecords = processOrder(filteredRecords, orderByOptions)
        }
        if (limitRows) {
            filteredRecords = filteredRecords.slice(0, limitRows)
        }

        return filteredRecords
    }
)

const defaultState = {
    loadingTimedOut: false,
}

type ViewConfig = {
    setConfig: (patch: Partial<ViewDto>, shouldSave?: boolean) => void
    setViewData: (patch: Partial<ViewDto>) => void
    saveConfig: () => Promise<void>
    isConfigDirty: boolean
    viewState: ViewState
    pageRoles: PageRole[]
    setPageRoles: (role: PageRole[]) => void
    rolesDirty: boolean
    revertChanges: () => void
}

type ListViewProps = {
    viewConfig: ViewConfig
    context?: ViewLayoutContext
    onChangeStack?: (...args: any[]) => Promise<StackDto>
    view?: ViewDto
    views?: ViewDto[]
    onError?: (error?: unknown) => void
    onRecordsLoaded?: (number: number | undefined | null) => void
    additionalFilters?: Filter[]
    limitRows?: boolean
    hideTitle?: boolean
    showControls?: boolean
    hideFields?: FieldDto[]
    isRecordList?: boolean
    hideEditControls?: boolean
    listEditControls?: React.ComponentType
    noLinks?: boolean
    isRecordListOnCreate?: boolean
    relatedFieldMayCreateNewRecords?: boolean
    relatedListSymmetricColumnName?: string
    title?: string
    autoFilledRelatedListRecord?: string
    relatedListType?: string
    parentDetailViewIds?: string[]
    parentListViewIds?: string[]
    relatedListAttrs?: {}
    relatedListRecord?: RecordDto
    relatedListEditing?: boolean
    recordListTitle?: string
    relatedListIds?: string[]
    relatedListField?: string
    history?: History
    location?: Location<{ objectId: string }>
    hideSearch?: boolean
    getPageSizeOptions?: (display?: string) => number[]
    getDefaultPageSize?: (display?: string) => number
    customListRenderer?: CustomListRendererComponent
    customEmptyState?: () => React.ReactElement
    customLoadingState?: CustomLoadingState
    allowDownload?: boolean
}

const getSearchQueryVariable = (variable: string): string[] | string | null => {
    const parsedQueryString = queryString.parse(window.location.search)
    return parsedQueryString[variable]
}

export const InnerListView: React.FC<ListViewProps> = ({
    context,
    view,
    onError: recordError,
    onRecordsLoaded: recordsLoaded,
    additionalFilters,
    limitRows,
    hideTitle,
    showControls,
    hideFields,
    isRecordList,
    hideEditControls,
    listEditControls,
    noLinks,
    isRecordListOnCreate,
    relatedFieldMayCreateNewRecords,
    relatedListSymmetricColumnName,
    autoFilledRelatedListRecord,
    location,
    title,
    history,
    relatedListType,
    parentDetailViewIds = [],
    parentListViewIds = [],
    relatedListField,
    recordListTitle,
    relatedListAttrs,
    relatedListRecord,
    relatedListEditing,
    relatedListIds = [],
    hideSearch,
    getPageSizeOptions,
    getDefaultPageSize,
    customListRenderer,
    customEmptyState,
    customLoadingState,
    allowDownload: allowDownloadOverride,
    viewConfig,
}) => {
    const { selectedStack: stack } = useAppContext()
    const stackOptions = stack?.options

    const { data: actions } = useActions()
    const { data: objects = [] } = useObjects({ stackId: stack?._sid } as any)

    const { data: views } = useViews({ stackId: stack?._sid } as any)

    const [state, setState] = useState(defaultState)
    const [currentRelatedListIds, setCurrentRelatedListIds] = useState(relatedListIds)
    const [key, setKey] = useState(Date.now())
    const loadingTimer = useRef(0)
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false)
    const [waitingForUserRecord, setWaitingForUserRecord] = useState(true)
    const [showSuccessToast, setShowSuccessToast] = useState(false)
    const [toastSuccessText, setToastSuccessText] = useState('')

    const { isFetched: userRecordFetched } = useUserRecord()
    const objectSid = view?.object_id
    const { object } = useObject(objectSid)

    const { track } = useTrack()

    const [lastFilters, setLastFilters] = useLastFilters(view, relatedListRecord)

    // this will allow child components to re-mount this component
    const forceUpdate = useCallback(() => setKey(Date.now()), [setKey])

    // re-fetch the related list after editing the parent record
    // only if the field has been updated
    useEffect(() => {
        if (
            isRecordList &&
            !relatedListEditing &&
            relatedListType !== 'all' &&
            !isArrayEqual(relatedListIds, currentRelatedListIds)
        ) {
            setCurrentRelatedListIds(relatedListIds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecordList, relatedListEditing, relatedListType, relatedListIds])

    useEffect(() => {
        forceUpdate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRelatedListIds])

    const onCreate = useCallback(
        (_newRecord, successText) => {
            forceUpdate()
            if (successText) {
                setShowLoadingOverlay(true)
                setToastSuccessText(successText)
            } else {
                setToastSuccessText('')
            }
        },
        [forceUpdate, setShowLoadingOverlay, setToastSuccessText]
    )

    const onRecordsLoadStarted = () => {
        const timer = window.setTimeout(() => {
            setState((state) => ({ ...state, loadingTimedOut: true }))
            setShowLoadingOverlay(false)
        }, 10 * 1000)
        loadingTimer.current = timer
    }

    const onRecordsLoaded = (numLoaded?: number | null) => {
        window.clearTimeout(loadingTimer.current)

        if (state.loadingTimedOut) {
            setState((state) => ({ ...state, loadingTimedOut: false }))
        }
        // null => Fetch cancelled.
        if (recordsLoaded && numLoaded !== null) {
            recordsLoaded(numLoaded)
        }

        if (toastSuccessText) setShowSuccessToast(true)
        setShowLoadingOverlay(false)
    }

    const onError = () => {
        window.clearTimeout(loadingTimer.current)
        if (recordError) {
            recordError()
        }
    }

    useEffect(() => {
        return () => {
            window.clearTimeout(loadingTimer.current)
        }
    }, [])

    const onDownloadCsv = async () => {
        track('Frontend - List View - Export CSV - Clicked', { table_name: object?.name })
        const csvIncludedFields = columns.reduce<string[]>((combined, column) => {
            if (column.field?.api_name && column.show !== false) {
                combined.push(column.field?.api_name)
            }
            return combined
        }, [])

        try {
            if (object) {
                await recordApi.downloadCsvRecords({
                    object,
                    filename:
                        `${stack?.name}_${object?.name}`.replace(/[^a-z0-9]/gi, '_').toLowerCase() +
                        '.csv',
                    filters: listViewFilters,
                    includeFields: csvIncludedFields,
                    excludeRecordsIdFromCsv: true,
                    disablePartials: showControls || showDetailView,
                    orderBy,
                    csvFieldsOrder: csvIncludedFields,
                })
            }
        } catch (error) {
            Sentry.captureException(error)
            track('Frontend - List View - Export CSV - Failed', { table_name: object?.name })
        }
    }

    const {
        setConfig,
        setViewData,
        saveConfig,
        isConfigDirty,
        viewState,
        setPageRoles,
        rolesDirty,
        pageRoles,
        revertChanges,
    } = viewConfig

    const {
        display,
        order,
        filters: viewStateFilters,
        title: viewStateTitle,
        showAllFields,
        columns: viewStateColumns,
        enableSpecificEndUserFilters,
        specificEndUserFilters,
        actionButtons,
        orderType: specifiedOrderType,
    } = viewState?.view?.options

    const orderType = display === 'kanban' ? specifiedOrderType : 'field'
    const allowDownload = allowDownloadOverride ?? viewState?.view?.options.allowDownload
    const mergedHideSearch =
        hideSearch === undefined ? viewState?.view?.options?.hide_search_bar : hideSearch

    const orderByParamsResult = useOrderByParams({
        adminOrderBy: order,
        disableParams: !!isRecordList,
    })
    const { orderBy, setOrderBy, orderByHasInitialised } = {
        ...orderByParamsResult,
        setOrderBy: orderType !== 'manual' ? orderByParamsResult.setOrderBy : undefined,
    }

    // check to see if any of our filters rely in current user record values
    // and if so, and we are still waiting on the user record info from the server
    // then show a loading state while we wait.
    useEffect(() => {
        const filters = viewStateFilters?.find(
            (filter) => filter?.options?.option && currentOptions.has(filter?.options?.option)
        )
        setWaitingForUserRecord(!userRecordFetched && !!filters)
    }, [userRecordFetched, viewStateFilters])

    const [allowRedirect, setAllowRedirect] = useState(false)
    const addFilters = additionalFilters || []
    const filters: Filter[] = useDeepEqualsMemoValue((viewStateFilters ?? []).concat(addFilters))

    // Initialize the filter with the search parameter from the URL if there's one.
    const [endUserFilters, setEndUserFilters] = useDeepEqualsState(
        getSearchQueryVariable('search')
            ? [
                  {
                      field: { api_name: '_search' },
                      options: { value: getSearchQueryVariable('search') },
                  },
              ]
            : []
    )
    const [endUserFilters__NotForBackend, setEndUserFilters__NotForBackend] = useDeepEqualsState([]) // used for charts

    const allFilters: Filter[] = useMemo(() => {
        return [...filters, ...endUserFilters]
    }, [filters, endUserFilters])

    const allFilters__NotForBackend: Filter[] = useMemo(() => {
        return [...filters, ...endUserFilters__NotForBackend]
    }, [filters, endUserFilters__NotForBackend])

    const isMobile = useIsMobile()
    const shouldIgnoreInbox = isMobile || isRecordList

    // This supports our functionality to only show a single record from this list
    const showDetailView = display === 'single_record'
    const showInbox = display === 'inbox' && !shouldIgnoreInbox

    const isListView = !showDetailView && !showInbox

    // Don't show the title while loading if we plan for the detail view to come in
    const hideTitleAndButtonsWhileLoading = showDetailView

    const disableHistoryBreadcrumb = isRecordList || !orderByHasInitialised
    const finalTitle = title || viewStateTitle || viewState?.view?.name
    const breadcrumbTitle = useViewBreadcrumbTitle(viewState?.view)

    useHistoryBreadcrumb(
        { title: breadcrumbTitle, type: 'list', objectId: objectSid },
        disableHistoryBreadcrumb
    )

    const titleLabel = useMemo(() => {
        // If we're on a detail view, don't show the title until the object has had a chance to load
        const showTitle = !hideTitleAndButtonsWhileLoading && !hideTitle
        if (!showTitle) return undefined

        return finalTitle
    }, [hideTitle, finalTitle, hideTitleAndButtonsWhileLoading])

    const rowLinkFunction = useCallback(
        (row: Row<RecordDto>) => {
            const link = getUrl(`${object?.url}/view/${row.original._sid}`, stack)

            return {
                pathname: link,
                state: {
                    prev: history?.location,
                    type: 'detail',
                    title: row?.original?._primary,
                },
            } as Location
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [history?.location, object?.url, stack?._sid]
    )

    const detailView = useMemo(() => {
        return views?.find((v) => v.object_id === object?._sid && v.type === 'detail')
    }, [views, object])

    const { previewRecord } = usePreviewRecordContext()

    const openRecord = useCallback(
        (recordId, recordListItems: RecordDto[] = []) => {
            if (showControls) {
                history?.push(rowLinkFunction({ original: { _sid: recordId } } as Row<RecordDto>))
            } else {
                let partOfRecordList: PreviewRecordList | undefined
                if (
                    recordListItems.length > 0 &&
                    ['card', 'table', 'rows', 'kanban'].includes(display)
                ) {
                    partOfRecordList = {
                        items: recordListItems.map((item) => ({ recordId: item._sid })),
                        direction: display === 'card' ? 'horizontal' : 'vertical',
                    }
                }

                if (object?._sid) {
                    previewRecord({
                        recordId,
                        objectId: object._sid,
                        partOfRecordList,
                    })
                }
            }
        },
        [showControls, history, rowLinkFunction, previewRecord, display, object?._sid]
    )

    const objectFields = object?.fields
    const objectPermissions = object?._permissions

    // If isShowAllFields, we map all fields to a column and override viewOptions for display options components
    const columnConfig: ListViewColumnConfig[] = useMemo(() => {
        if (!object) return []
        return getColumnConfig(object, viewStateColumns, showAllFields)
    }, [object, showAllFields, viewStateColumns])

    const overrideViewOptions = useMemo(() => {
        return { ...viewState?.view?.options, columns: columnConfig }
    }, [columnConfig, viewState])

    const setViewOptions = useCallback(
        (patch: Partial<ListViewOptions>) => {
            setConfig({
                ...viewState?.view?.options,
                ...patch,
            })
        },
        [setConfig, viewState?.view?.options]
    )

    const columns: ViewColumn[] = useMemo(() => {
        // Hide read=false fields by api_name using permissions
        let fieldsToHideNames = objectFields?.map((field) => field.api_name) ?? []
        if (objectPermissions) {
            const visibleFields = new Set(
                objectPermissions.may_read_fields.concat(objectPermissions.maybe_may_read_fields)
            )

            fieldsToHideNames = fieldsToHideNames.filter((name) => !visibleFields.has(name))
        }

        // We sometimes allow columns to be hidden (e.g. in a detail view)
        const fieldsToHideIDs = hideFields?.map((field) => field._sid) ?? []

        return getColumns({
            columnConfig,
            fieldsToHideNames,
            fieldsToHideIDs,
            objectFields: objectFields ?? [],
            viewState,
        })
    }, [columnConfig, hideFields, objectFields, objectPermissions, viewState])

    const userFilterFieldIds = useMemo(() => {
        if (enableSpecificEndUserFilters) {
            return specificEndUserFilters ?? []
        }

        const idsSet = columns.reduce<Set<string>>((agg, curr) => {
            if (curr.field) {
                agg.add(curr.field._sid)
            }

            return agg
        }, new Set())

        return [...idsSet]
    }, [columns, enableSpecificEndUserFilters, specificEndUserFilters])

    const { mergedColumns, hiddenColumnIds } = useMemo(() => {
        const columnFieldIds = columns.reduce((agg, curr) => {
            if (curr.field?._sid) agg.add(curr.field?._sid)
            return agg
        }, new Set<string>())

        // in Specific End User Filters mode some of the filters are on fields that are not in the view. To make it work,
        // we need to create hidden columns for those fields.
        const userFiltersHidden = userFilterFieldIds.reduce((agg, curr) => {
            if (!columnFieldIds.has(curr)) agg.add(curr)
            return agg
        }, new Set())

        const hiddenColumns: ViewColumn[] = getHiddenColumnsForUserFilter(
            objectFields?.filter((field) => userFiltersHidden.has(field._sid)) ?? []
        )

        return {
            mergedColumns: columns.concat(hiddenColumns),
            hiddenColumnIds: hiddenColumns.map((column) => column.id!),
        }
    }, [columns, objectFields, userFilterFieldIds])

    const multiLookupSids = useMemo(() => {
        return (
            columns?.reduce((agg: string[], curr) => {
                if (curr.type === 'multi_lookup' && curr.field) {
                    agg.push(curr.field._sid)
                }

                return agg
            }, []) ?? []
        )
    }, [columns])

    // lookup objects to watch for realtime updates
    const lookupObjects = useMemo(() => {
        return (
            columns?.reduce((agg: string[], curr) => {
                const lookupTarget = curr.field?.link_target_object_id
                if (lookupTarget && (curr.type === 'lookup' || curr.type === 'multi_lookup')) {
                    agg.push(lookupTarget)
                }

                return agg
            }, []) ?? []
        )
    }, [columns])

    const effectiveKanbanSettings = useKanbanSettings({
        viewId: viewState?.view?._sid,
        viewOptions: viewState?.view?.options,
    })
    const layoutSpecificFilters = useMemo((): Filter[] => {
        switch (display) {
            case 'kanban':
                return (
                    (object &&
                        (getKanbanFilters(
                            object,
                            effectiveKanbanSettings.selectedStatusFieldId,
                            effectiveKanbanSettings.selectedStatusColumns
                        ) as Filter[] | undefined)) ||
                    []
                )
            default:
                return []
        }
    }, [
        display,
        effectiveKanbanSettings.selectedStatusColumns,
        effectiveKanbanSettings.selectedStatusFieldId,
        object,
    ])

    const includeFields = useMemo(() => {
        let filterFields: string[] = getFilterFields(allFilters.concat(layoutSpecificFilters))
        if (orderBy) {
            filterFields = filterFields.concat(getOrderFields([orderBy]))
        }

        // Get action buttons which have conditional visibility set
        const conditionalButtons =
            actionButtons?.filter(
                (x) => typeof x.conditions !== 'undefined' && x.conditions.length > 0
            ) || []

        // Get a flattened version of all active conditions
        const conditions = conditionalButtons?.map((button) => button.conditions).flat() || []

        // Remove conditions that do not have a specific field (i.e. the user role filter)
        for (const condition of conditions) {
            if (condition.field?.api_name && condition.field.type !== 'user_role') {
                filterFields.push(condition.field.api_name)
            }
        }

        for (const column of mergedColumns) {
            if (column.field?.api_name) {
                filterFields.push(column.field.api_name)
            }
        }

        // TODO: this should be merged with the logic in detailRequiredFields.ts and useIncludedFields.ts.
        for (const button of actionButtons ?? []) {
            const actionSid = button.id
            if (actionSid) {
                const action = (actions ?? []).find(
                    (action: ActionDto) => action._sid === actionSid
                )
                if (action) {
                    for (const step of action.options.steps.filter(
                        (step: ActionStep) => step.type === 'updateRecord'
                    )) {
                        for (const stepField of step.fields) {
                            filterFields.push(stepField.fieldName)
                        }
                    }
                }
            }
        }

        return uniq(filterFields)
    }, [actions, allFilters, layoutSpecificFilters, mergedColumns, orderBy, actionButtons])

    const query = useMemo(() => queryString.parse(location?.search as string), [location?.search])

    // Kanban doesn't support paging, so fetch all data
    const defaultPageSize = getServerDefaultPageSize(display, showDetailView, view)

    let defaultPageIndex = 0
    // Only look at the page number from the URL if we're not in the main app record list - otherwise, this will cause
    // a bug where other list views (e.g., the related record list in a preview) will start loading records from
    // the wrong page.
    if (query.page_num && !isRecordList) {
        defaultPageIndex = parseInt(query.page_num as string, 10) - 1
    }

    const [pageIndex, setPageIndex] = useState(defaultPageIndex)
    const [pageSize, setPageSize] = useState(defaultPageSize)

    const listViewFilters = useMemo((): Filter[] | undefined => {
        return allFilters.concat(layoutSpecificFilters)
    }, [allFilters, layoutSpecificFilters])

    const { data: totalResults } = useRecordCount({
        objectSid: objectSid ?? '',
        filters: listViewFilters,
        fetchOptions: {
            includeFields:
                includeFields && allFilters.some((filter) => filter?.field?.api_name === '_search')
                    ? includeFields
                    : [],
        },
    })

    // This only happens if a record list then tries to call the same detail view
    // we need to stop this from happening to stop an endless loop
    const hasDuplicateDetailViewID = useMemo(() => {
        return showDetailView && detailView && parentDetailViewIds.includes(detailView._sid)
    }, [detailView, parentDetailViewIds, showDetailView])
    if (hasDuplicateDetailViewID) {
        console.log(
            `Stopping list rendering due to duplicate detail view id in the tree. View Id ${detailView?._sid}`
        )
        return null
    }

    if (!objects) return null

    if (objects.length === 0 || !object) {
        if (customEmptyState) return customEmptyState()

        return (
            <ListViewObjectNotAccessible
                setConfig={setConfig}
                display={display}
                title={titleLabel}
                showControls={showControls}
                isRecordList={isRecordList}
                isMobile={isMobile}
            />
        )
    }

    if (!objectFields || !objectPermissions) return null
    if (isListView && !showControls && !columns.length) return null
    // This prevents a wasted render. We have a useEffect which updates
    // the location.state with breadcrumb information. That update of location
    // causes a rerender of the page immediately after the initial render.
    // This line avoids rendering at all if we haven't updated the location.state yet
    if (!disableHistoryBreadcrumb && location?.state?.objectId !== objectSid) return null

    return (
        <>
            {!isRecordList && !showInbox && context && (
                <ListViewHeader
                    viewLayoutContext={context}
                    listOptions={viewState?.view?.options}
                    onChange={setConfig}
                />
            )}
            {/* If we have to wait for the current user's profile records to load,
            before we can filter properly, show the loading state now. */}
            {waitingForUserRecord ? (
                <ListViewLoadingState
                    setConfig={setConfig}
                    display={display}
                    showControls={showControls}
                    title={titleLabel}
                    isMobile={isMobile}
                    loadingTimedOut={state.loadingTimedOut}
                    customLoader={customLoadingState}
                />
            ) : (
                <WithRecords
                    key={key}
                    ignoreLoading={showControls}
                    renderOnError
                    objectSid={objectSid ?? ''}
                    object={object}
                    viewOptions={overrideViewOptions}
                    filters={listViewFilters}
                    options={{
                        dereference: showDetailView ? false : true,
                        dereferenceMultiFields: showDetailView ? undefined : multiLookupSids,
                        includeFields,
                        disablePartials: showControls || showDetailView,
                        orderBy,
                        viewId: viewState?.view?._sid,
                        manualOrderKey:
                            overrideViewOptions.orderType === 'manual'
                                ? `${viewState?.view?.stack_id}_${viewState?.view._sid}`
                                : undefined,
                        pageSize,
                        pageIndex,
                        stackId: stack?._sid,
                    }}
                    loading={
                        <ListViewLoadingState
                            setConfig={setConfig}
                            display={display}
                            showControls={showControls}
                            title={titleLabel}
                            isMobile={isMobile}
                            loadingTimedOut={state.loadingTimedOut}
                            customLoader={customLoadingState}
                        />
                    }
                    onRecordsLoadStarted={onRecordsLoadStarted}
                    onRecordsLoaded={onRecordsLoaded}
                    onError={onError}
                    internalOptions={{ lookupObjects }}
                >
                    {({
                        records,
                        isLoading,
                        isServerLoading,
                        dereferencedRecords,
                        loadingFailed,
                    }) => {
                        const filteredRecords = getFilteredOrderedRecords(
                            records,
                            allFilters ?? [],
                            relatedListRecord,
                            limitRows,
                            orderBy,
                            orderType
                        )

                        const isDetailView = showDetailView

                        // If we're either still performing the initial load (isLoading)
                        // or we have no results (because we filtered the available ones out)
                        // and we're still waiting for the server response, show the loading indicator
                        const showLoadingState =
                            isLoading ||
                            (isServerLoading && filteredRecords.length === 0) ||
                            loadingFailed

                        const showCreate = !Boolean(viewState?.view?.options?.disableCreateForm)

                        const defaultedOpenRecord: OpenRecordFunction | undefined = (
                            recordId: string,
                            partOfList: RecordDto[] = filteredRecords
                        ) => openRecord(recordId, partOfList)

                        return (
                            <>
                                {showControls && (
                                    <ListViewEditPane
                                        viewLayoutContext={context}
                                        saveConfig={saveConfig}
                                        setConfig={setViewOptions}
                                        setViewData={setViewData}
                                        setAllowRedirect={setAllowRedirect}
                                        setPageRoles={setPageRoles}
                                        viewState={viewState}
                                        isConfigDirty={isConfigDirty}
                                        isRolesDirty={rolesDirty}
                                        showDetailView={showDetailView}
                                        showCreate={showCreate}
                                        object={object}
                                        view={view}
                                        views={views}
                                        filteredRecords={filteredRecords}
                                        pageRoles={pageRoles}
                                        detailView={detailView}
                                        columnConfig={columnConfig}
                                        getDefaultPageSize={getDefaultPageSize}
                                        getPageSizeOptions={getPageSizeOptions}
                                    />
                                )}
                                {showLoadingState ? (
                                    <ListViewLoadingState
                                        setConfig={setConfig}
                                        display={display}
                                        showControls={showControls}
                                        title={titleLabel}
                                        isMobile={isMobile}
                                        failed={loadingFailed}
                                        loadingTimedOut={state.loadingTimedOut}
                                        customLoader={customLoadingState}
                                    />
                                ) : (
                                    <>
                                        {isListView && (
                                            <ListViewEditMode
                                                title={titleLabel}
                                                setConfig={setConfig}
                                                showControls={showControls}
                                                lastFilters={lastFilters}
                                                setLastFilters={setLastFilters}
                                                setEndUserFilters={setEndUserFilters}
                                                setEndUserFilters__NotForBackend={
                                                    setEndUserFilters__NotForBackend
                                                }
                                                rowLinkFunction={rowLinkFunction}
                                                setOrderBy={setOrderBy}
                                                isRecordList={isRecordList}
                                                noLinks={noLinks}
                                                isServerLoading={isServerLoading}
                                                view={view}
                                                viewState={viewState}
                                                object={object}
                                                columns={mergedColumns}
                                                hiddenColumnIds={hiddenColumnIds}
                                                userFilterFieldIds={userFilterFieldIds}
                                                filteredRecords={filteredRecords}
                                                overrideViewOptions={overrideViewOptions}
                                                endUserFilters={endUserFilters}
                                                allFilters__NotForBackend={
                                                    allFilters__NotForBackend
                                                }
                                                dereferencedRecords={dereferencedRecords}
                                                totalResults={totalResults}
                                                relatedFieldMayCreateNewRecords={
                                                    relatedFieldMayCreateNewRecords
                                                }
                                                hideEditControls={hideEditControls}
                                                isRecordListOnCreate={isRecordListOnCreate}
                                                listEditControls={listEditControls}
                                                relatedListSymmetricColumnName={
                                                    relatedListSymmetricColumnName
                                                }
                                                relatedListType={relatedListType}
                                                autoFilledRelatedListRecord={
                                                    autoFilledRelatedListRecord
                                                }
                                                orderBy={orderBy}
                                                relatedListAttrs={relatedListAttrs}
                                                history={history}
                                                location={location}
                                                relatedListField={relatedListField}
                                                hideSearch={mergedHideSearch}
                                                getPageSizeOptions={getPageSizeOptions}
                                                getDefaultPageSize={getDefaultPageSize}
                                                customListRenderer={customListRenderer}
                                                allowDownload={allowDownload}
                                                onDownloadCsv={onDownloadCsv}
                                                openRecord={defaultedOpenRecord}
                                                setPageIndex={setPageIndex}
                                                setPageSize={setPageSize}
                                                pageSize={pageSize}
                                                pageIndex={pageIndex}
                                            />
                                        )}
                                        {isDetailView && detailView && (
                                            <DetailView
                                                view={detailView}
                                                objectId={detailView.object_id}
                                                config={detailView.options}
                                                showControls={false}
                                                recordSid={filteredRecords[0]?._sid}
                                                isRecordList={isRecordList}
                                                showCreate={showCreate}
                                                onCreate={onCreate}
                                                parentDetailViewIds={
                                                    detailView
                                                        ? [...parentDetailViewIds, detailView._sid]
                                                        : parentDetailViewIds
                                                }
                                                parentListViewIds={
                                                    view
                                                        ? [...parentListViewIds, view._sid]
                                                        : parentListViewIds
                                                }
                                                title={recordListTitle}
                                                fromListView
                                            />
                                        )}
                                        {showInbox && detailView && (
                                            <ListViewInboxMode
                                                setConfig={setConfig}
                                                showControls={showControls}
                                                isRecordList={isRecordList}
                                                lastFilters={lastFilters}
                                                setLastFilters={setLastFilters}
                                                noLinks={noLinks}
                                                isServerLoading={isServerLoading}
                                                detailView={detailView}
                                                viewState={viewState}
                                                object={object}
                                                showCreate={showCreate}
                                                onCreate={onCreate}
                                                stackOptions={stackOptions}
                                                parentDetailViewIds={parentDetailViewIds}
                                                parentListViewIds={parentListViewIds}
                                                recordListTitle={recordListTitle}
                                                title={titleLabel}
                                                columns={mergedColumns}
                                                filteredRecords={filteredRecords}
                                                overrideViewOptions={overrideViewOptions}
                                                setEndUserFilters={setEndUserFilters}
                                                setEndUserFilters__NotForBackend={
                                                    setEndUserFilters__NotForBackend
                                                }
                                                rowLinkFunction={rowLinkFunction}
                                                endUserFilters={endUserFilters}
                                                dereferencedRecords={dereferencedRecords}
                                                totalResults={totalResults}
                                                relatedFieldMayCreateNewRecords={
                                                    relatedFieldMayCreateNewRecords
                                                }
                                                hideEditControls={hideEditControls}
                                                isRecordListOnCreate={isRecordListOnCreate}
                                                listEditControls={listEditControls}
                                                relatedListSymmetricColumnName={
                                                    relatedListSymmetricColumnName
                                                }
                                                relatedListType={relatedListType}
                                                autoFilledRelatedListRecord={
                                                    autoFilledRelatedListRecord
                                                }
                                                history={history}
                                                location={location}
                                                userFilterFieldIds={userFilterFieldIds}
                                                hideSearch={mergedHideSearch}
                                                getPageSizeOptions={getPageSizeOptions}
                                                getDefaultPageSize={getDefaultPageSize}
                                                allowDownload={allowDownload}
                                                onDownloadCsv={onDownloadCsv}
                                                setPageIndex={setPageIndex}
                                                setPageSize={setPageSize}
                                                pageSize={pageSize}
                                                pageIndex={pageIndex}
                                            />
                                        )}
                                    </>
                                )}
                                <UnsavedChangesModal
                                    isDirty={(isConfigDirty || rolesDirty) && !allowRedirect}
                                    onSave={saveConfig}
                                    revertChanges={() => {
                                        revertChanges()
                                    }}
                                />
                            </>
                        )
                    }}
                </WithRecords>
            )}
            <Toast
                show={showSuccessToast}
                onCloseComplete={() => setShowSuccessToast(false)}
                title={toastSuccessText}
            />
            <LoadingOverlay isOpen={showLoadingOverlay} />
        </>
    )
}

type WithRecordsProps = React.ComponentPropsWithoutRef<typeof WithRecordsBase> & {
    viewOptions: ListViewOptions
    object: ObjectDto
}

const WithRecords: React.FC<WithRecordsProps> = ({ children, viewOptions, object, ...props }) => {
    return (
        <WithRecordsBase {...props}>
            {({ records: originalRecords, onChange, addRecord, ...rest }) => {
                return (
                    <RecordEditManagerContextProvider
                        records={originalRecords}
                        updateRecord={(recordId, patch) => {
                            return onChange(recordId, patch, { deferStoreUpdate: true })
                        }}
                        addRecord={addRecord}
                    >
                        <RecordEditManagerContext.Consumer>
                            {(recordManager) => {
                                const { records } = recordManager!

                                const pendingRecordIds = getPendingRecordIds(recordManager!)

                                return (
                                    <ListViewRecordContextMenuWrapper
                                        records={records}
                                        object={object}
                                        viewOptions={viewOptions}
                                        pendingRecordIds={pendingRecordIds}
                                    >
                                        {({ onChildPointerDown }) => (
                                            <div
                                                id="newwrapper"
                                                style={{
                                                    flexDirection: 'column',
                                                    display: 'flex',
                                                    width: '100%',
                                                    flexGrow: 1,
                                                }}
                                                onPointerDownCapture={onChildPointerDown}
                                            >
                                                {children({
                                                    records,
                                                    onChange,
                                                    addRecord,
                                                    ...rest,
                                                })}
                                            </div>
                                        )}
                                    </ListViewRecordContextMenuWrapper>
                                )
                            }}
                        </RecordEditManagerContext.Consumer>
                    </RecordEditManagerContextProvider>
                )
            }}
        </WithRecordsBase>
    )
}

// @ts-expect-error
export const ListView = withRouter(InnerListView)
