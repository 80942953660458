import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useMainNavigationState() {
    const { mainNavState, selectedApp, isPortal } = useNavigationContext()

    const isNavCollapsed = mainNavState === 'collapsed' || (!selectedApp && !isPortal)

    return useMemo(
        () => ({
            isNavCollapsed,
            isPortal,
        }),
        [isNavCollapsed, isPortal]
    )
}
