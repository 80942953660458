import { useMemo } from 'react'

import useLDFlags from 'data/hooks/useLDFlags'
import { useTasksEnabled } from 'features/tasks/useTasksEnabled'

import { useNavigationContext } from './useNavigationContext'

export function useSystemNavigationState() {
    const { isPortal } = useNavigationContext()
    const { flags } = useLDFlags()

    const showSearch = flags.search
    const showNotifications = !isPortal && flags.notifications
    const showTasks = useTasksEnabled()

    return useMemo(
        () => ({
            showSearch,
            showNotifications,
            showTasks,
        }),
        [showSearch, showNotifications, showTasks]
    )
}
