import React from 'react'

import { useNavigationState } from './hooks/useNavigationState'
import { MainNavigation } from './MainNavigation'
import * as Parts from './Navigation.parts'
import { WorkspaceNavigation } from './WorkspaceNavigation'

type NavigationProps = {}

export const Navigation: React.FC<NavigationProps> = () => {
    const { workspaceNavState } = useNavigationState()

    return (
        <Parts.NavigationWrapper workspaceNavState={workspaceNavState}>
            <WorkspaceNavigation />
            <MainNavigation />
        </Parts.NavigationWrapper>
    )
}
