import React from 'react'

import { Popup, PopupContent, PopupTrigger } from 'ui/components/Popup'

import { useSystemNavigationPopupItemState } from './hooks/useSystemNavigationPopupItemState'
import { SystemNavigationItem } from './SystemNavigationItem'

import { SystemNavigationPopupStyle } from './Navigation.css'

type SystemNavigationPopupItemProps = React.ComponentPropsWithoutRef<
    typeof SystemNavigationItem
> & {}

export const SystemNavigationPopupItem: React.FC<SystemNavigationPopupItemProps> = ({
    children,
    ...props
}) => {
    const { isOpen, setIsOpen, onInteractOutside, isFirstRender } =
        useSystemNavigationPopupItemState()

    return (
        <Popup open={isOpen} onOpenChange={setIsOpen}>
            <PopupTrigger asChild>
                <SystemNavigationItem {...props} />
            </PopupTrigger>
            <PopupContent
                sideOffset={16}
                alignOffset={-82}
                align="start"
                side="right"
                style={{ zIndex: 200, width: '482px', maxWidth: '100%' }}
                className={SystemNavigationPopupStyle}
                onInteractOutside={onInteractOutside}
            >
                {isOpen && !isFirstRender && children}
            </PopupContent>
        </Popup>
    )
}
