import React from 'react'

import { Box } from 'ui/components/Box'
import { ScrollArea } from 'ui/components/ScrollArea'

import { useNavigationLinksState } from './hooks/useNavigationLinksState'

import { WorkspaceNavigationLinksWrapperStyle } from './Navigation.css'

type WorkspaceNavigationLinksProps = {
    isCollapsed?: boolean
}

export const WorkspaceNavigationLinks: React.FC<WorkspaceNavigationLinksProps> = ({
    children,
    isCollapsed,
}) => {
    const { wrapperRef, scrollAreaRef } = useNavigationLinksState()

    return (
        <Box ref={wrapperRef} className={WorkspaceNavigationLinksWrapperStyle}>
            <ScrollArea
                ref={scrollAreaRef}
                direction="vertical"
                width="full"
                rootProps={{
                    style: {
                        marginTop: isCollapsed ? '-8px' : undefined,
                        height: isCollapsed ? 'calc(100% + 8px)' : '100%',
                    },
                    width: 'full',
                    display: 'grid',
                }}
            >
                <Box
                    pt={isCollapsed ? '2xl' : 'xl'}
                    pb={isCollapsed ? 0 : 'm'}
                    flex
                    column
                    gap={isCollapsed ? 'l' : 'xl'}
                >
                    {children}
                </Box>
            </ScrollArea>
        </Box>
    )
}
