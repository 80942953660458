import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Tooltip } from 'ui/components/Tooltip'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { useWorkspaceNavigationState } from './hooks/useWorkspaceNavigationState'
import * as Parts from './Navigation.parts'
import { NavigationCurrentUserDropdown } from './NavigationCurrentUserDropdown'
import { NavigationPortals } from './NavigationPortals'
import { NavigationSpaces } from './NavigationSpaces'
import { SystemNavigation } from './SystemNavigation'
import { WorkspaceLogo } from './WorkspaceLogo'
import { WorkspaceNavigationLinks } from './WorkspaceNavigationLinks'

import { WorkspaceNavigationCollapsedToggleStyle } from './Navigation.css'

type WorkspaceNavigationProps = {}

export const WorkspaceNavigation: React.FC<WorkspaceNavigationProps> = () => {
    const {
        navWrapperRef,
        isWorkspaceNavCollapsed,
        workspaceNavState,
        toggleWorkspaceNavCollapsed,
        tooltipText,
        canEditWorkspaceSettings,
        handleOpenWorkspaceSettings,
        hasAppSelected,
    } = useWorkspaceNavigationState()

    return (
        <ThemeProvider theme={hasAppSelected ? 'default' : 'admin'}>
            <Parts.WorkspaceNavigationWrapper ref={navWrapperRef} state={workspaceNavState}>
                {workspaceNavState !== 'static' && (
                    <Tooltip
                        asChild
                        side="bottom"
                        align="center"
                        content={tooltipText}
                        zIndex={201}
                    >
                        <Button
                            startIcon={{
                                name: isWorkspaceNavCollapsed ? 'ChevronRight' : 'ChevronLeft',
                            }}
                            size="s"
                            variant="secondary"
                            onClick={toggleWorkspaceNavCollapsed}
                            className={WorkspaceNavigationCollapsedToggleStyle}
                            aria-label={tooltipText}
                        />
                    </Tooltip>
                )}
                <Box flex column height="full">
                    <Box
                        flex
                        column
                        height="full"
                        px={isWorkspaceNavCollapsed ? 's' : 'xs'}
                        grow
                        shrink
                    >
                        <Box
                            flex
                            center
                            justifyContent={isWorkspaceNavCollapsed ? 'center' : 'space-between'}
                            pt="l"
                            pl={isWorkspaceNavCollapsed ? 0 : 'm'}
                            pr={isWorkspaceNavCollapsed ? 0 : '2xs'}
                            mb="xl"
                        >
                            <WorkspaceLogo />
                            {!isWorkspaceNavCollapsed && canEditWorkspaceSettings && (
                                <Tooltip
                                    asChild
                                    side="bottom"
                                    align="center"
                                    content="Workspace settings"
                                    zIndex={201}
                                >
                                    <Button
                                        size="xs"
                                        variant="ghost"
                                        startIcon={{ name: 'Settings' }}
                                        aria-label="Workspace settings"
                                        onClick={handleOpenWorkspaceSettings}
                                    />
                                </Tooltip>
                            )}
                        </Box>
                        <Box flex column grow shrink>
                            <SystemNavigation isCollapsed={isWorkspaceNavCollapsed} />
                            <WorkspaceNavigationLinks isCollapsed={isWorkspaceNavCollapsed}>
                                <NavigationSpaces isCollapsed={isWorkspaceNavCollapsed} />
                                <NavigationPortals isCollapsed={isWorkspaceNavCollapsed} />
                            </WorkspaceNavigationLinks>
                        </Box>
                    </Box>
                    <NavigationCurrentUserDropdown isCollapsed={isWorkspaceNavCollapsed} />
                </Box>
            </Parts.WorkspaceNavigationWrapper>
        </ThemeProvider>
    )
}
