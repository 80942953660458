// @ts-strict-ignore
import * as React from 'react'

import { Rights } from 'app/accountUserContextConstants'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'

import { SidebarItem } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { WorkspaceSettingsPage } from './types'

type SidebarItem = {
    id: WorkspaceSettingsPage
    name: string
    icon: string
    internalOnly?: boolean
    rightRequired?: string
}

const menu: SidebarItem[] = [
    {
        name: 'General',
        id: 'general',
        icon: 'cog',
    },
    {
        name: 'Appearance',
        id: 'appearance',
        icon: 'brush',
    },
    {
        name: 'Collaborators',
        id: 'users',
        icon: 'users',
        internalOnly: true,
    },
    {
        name: 'Advanced',
        id: 'advanced',
        icon: 'cogs',
        rightRequired: Rights.ManageUsers,
        internalOnly: true,
    },
]
export function Sidebar({ onChange, active }) {
    const { hasRight } = useAccountUserContext()
    const { workspaceZone } = useWorkspaceContext()

    if (!workspaceZone) return null
    return (
        <Box
            flex
            column
            style={{
                width: 250,
                borderRight: '1px solid ' + V4DesignSystem.colors.gray[100],
                overflowY: 'auto',
                padding: 20,
                flexShrink: 0,
            }}
        >
            <Body size="m" color="textWeaker" weight="bold" pl="2xs">
                Settings
            </Body>
            <div style={{ height: 5 }} />
            {menu
                .filter(({ rightRequired }) => !rightRequired || hasRight(rightRequired))
                .filter(({ internalOnly }) => !internalOnly || workspaceZone.type === 'Internal')
                .map(({ icon, name, id }) => (
                    <React.Fragment key={id}>
                        <SidebarItem
                            active={active === id}
                            onChange={() => onChange(id)}
                            icon={icon}
                        >
                            {name}
                        </SidebarItem>
                        <div style={{ height: 1 }} />
                    </React.Fragment>
                ))}
        </Box>
    )
}
