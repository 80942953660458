import {
    APP_NAVIGATION_WIDTH,
    WORKSPACE_NAVIGATION_COLLAPSED_WIDTH,
    WORKSPACE_NAVIGATION_WIDTH,
} from 'features/navigation/Navigation.css'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationWidth() {
    const { mainNavState, workspaceNavState } = useNavigationContext()

    switch (workspaceNavState) {
        case 'collapsed':
            if (mainNavState === 'static') {
                return WORKSPACE_NAVIGATION_COLLAPSED_WIDTH + APP_NAVIGATION_WIDTH
            }

            return WORKSPACE_NAVIGATION_COLLAPSED_WIDTH

        case 'expanded':
            if (mainNavState === 'static') {
                return WORKSPACE_NAVIGATION_COLLAPSED_WIDTH + APP_NAVIGATION_WIDTH
            }

            return WORKSPACE_NAVIGATION_WIDTH

        case 'static':
            if (mainNavState === 'static') {
                return WORKSPACE_NAVIGATION_WIDTH + APP_NAVIGATION_WIDTH
            }

            return WORKSPACE_NAVIGATION_WIDTH

        default:
            return 0
    }

    return 0
}
