import React from 'react'

import { Flex, LoadingScreen, Tabs } from 'v2/ui'

import { ManageDataTabsList } from './ManageDataTabsList'
import { ManageDataTabsPanel } from './ManageDataTabsPanel'
import type { ManageDataTabsProps } from './types'

export const ManageDataTabs: React.VFC<ManageDataTabsProps> = ({
    isLoading,
    selectedTabIndex,
    leftTabsHeader,
    objects,
    onSelectObject,
    emptyState,
    isDirtyPanes,
    updateDirtyPanes,
    actions,
}) => (
    <LoadingScreen isLoading={isLoading} keepChildrenMounted>
        <Tabs
            index={selectedTabIndex}
            variant="line"
            height="100%"
            maxHeight="100%"
            d="flex"
            flexDirection="column"
            maxWidth="100%"
            // @ts-ignore
            isLazy
        >
            <Flex flexGrow={0} flexShink={1} minWidth={0} flexWrap="nowrap">
                {leftTabsHeader}
                {objects && objects.length > 0 && (
                    <ManageDataTabsList
                        objects={objects}
                        selectedTabIndex={selectedTabIndex}
                        onSelectObject={onSelectObject}
                        isDirtyPanes={isDirtyPanes}
                        actions={actions}
                    />
                )}
            </Flex>

            <ManageDataTabsPanel
                selectedTabIndex={selectedTabIndex}
                objects={objects}
                updateDirtyPanes={updateDirtyPanes}
                emptyState={emptyState}
            />
        </Tabs>
    </LoadingScreen>
)
