import { useMemo } from 'react'

import { WorkspaceNavigationLinksIsOverflowingBottomStyle } from 'features/navigation/Navigation.css'
import { useOverflowY } from 'features/views/ListView/hooks/useOverflowY'

export function useNavigationLinksState() {
    const { targetRef: wrapperRef, scrollAreaRef } = useOverflowY({
        bottomClassName: WorkspaceNavigationLinksIsOverflowingBottomStyle,
    })

    return useMemo(
        () => ({
            wrapperRef: wrapperRef as React.MutableRefObject<HTMLDivElement>,
            scrollAreaRef,
        }),
        [scrollAreaRef, wrapperRef]
    )
}
