import React from 'react'

import { Box } from 'ui/components/Box'

import { PortalApps } from './PortalApps'
import { PortalNavigationHeader } from './PortalNavigationHeader'

type PortalNavigationProps = {}

export const PortalNavigation: React.FC<PortalNavigationProps> = () => {
    return (
        <Box height="full">
            <PortalNavigationHeader />
            <PortalApps />
        </Box>
    )
}
