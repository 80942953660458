import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { FieldIcon as FieldIconValue } from 'data/utils/fieldDefinitions'

import STYLE_CLASSES from 'v2/ui/styleClasses'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownContent, DropdownTitle, DropdownTrigger } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'
import { IconPicker } from 'ui/components/IconPicker'
import { TriggerStyles } from 'ui/components/Select/Select.css'

import { FieldIcon } from './FieldIcon'
import { getDefaultFieldIcon } from './utils'

type FieldIconPickerProps = {
    value?: FieldIconValue
    field: FieldDto
    onChange?: (value?: FieldIconValue) => void
    disabled?: boolean
}

export const FieldIconPicker: React.FC<FieldIconPickerProps> = ({
    value,
    field,
    onChange,
    disabled,
}) => {
    const fieldRef = useRef(field)
    fieldRef.current = field

    const effectiveValue = value ?? getDefaultFieldIcon(field)

    const handleChange = useCallback(
        (value?: IconName) => {
            if (value) {
                onChange?.({ type: 'lucide', name: value })
            } else {
                const field = fieldRef.current
                const defaultValue = getDefaultFieldIcon(field)
                onChange?.(defaultValue)
            }
        },
        [onChange]
    )

    const [isOpen, setIsOpen] = useState(false)

    const updateAdminSideTrayState = useCallback((open: boolean) => {
        const adminTrayEl = document.querySelector(
            `.${STYLE_CLASSES.ADMIN_SIDE_TRAY_CONTENT}`
        ) as HTMLElement | null

        // Prevent the admin tray from being interacted with while the dropdown is open.
        // This is to fix an issue where you could click through the dropdown on some browsers.
        if (adminTrayEl) {
            adminTrayEl.style.pointerEvents = open ? 'none' : 'auto'
        }
    }, [])

    const onOpenChange = useCallback(
        (open: boolean) => {
            updateAdminSideTrayState(open)
            setIsOpen(open)
        },
        [updateAdminSideTrayState]
    )

    // Make sure we never leave the admin tray in a disabled state.
    useLayoutEffect(() => {
        return () => {
            updateAdminSideTrayState(false)
        }
    }, [updateAdminSideTrayState])

    return (
        <Dropdown open={isOpen} onOpenChange={onOpenChange} modal>
            <DropdownTrigger
                className={TriggerStyles.styleFunction({ size: 'm' })}
                style={{
                    width: 'auto',
                }}
                disabled={disabled}
                aria-disabled={disabled}
                asChild
            >
                <Box flex center gap="xs" role="button">
                    {effectiveValue ? (
                        <FieldIcon value={effectiveValue} size="m" />
                    ) : (
                        <Box height="xl" width="xl" rounded="s" background="surfaceStrongest" />
                    )}
                    <Icon size="m" name="ChevronDown" />
                </Box>
            </DropdownTrigger>
            <DropdownContent align="end">
                <DropdownTitle>Icons</DropdownTitle>
                <IconPickerWithDelay
                    value={effectiveValue?.name ?? null}
                    onChange={handleChange}
                    isClearable
                />
            </DropdownContent>
        </Dropdown>
    )
}

const IconPickerWithDelay: React.FC<React.ComponentPropsWithoutRef<typeof IconPicker>> = (
    props
) => {
    const [isFirstRender, setIsFirstRender] = useState(true)

    // Delay the rendering of the icon picker to prevent
    // it from making the dropdown laggy.
    useEffect(() => {
        setTimeout(() => {
            setIsFirstRender(false)
        }, 0)
    }, [])

    return (
        <Box px="xl">
            {isFirstRender ? (
                <Box
                    style={{
                        width: '258px',
                        height: '250px',
                    }}
                />
            ) : (
                <IconPicker
                    variant="lucide"
                    style={{
                        width: '258px',
                        height: '250px',
                    }}
                    {...props}
                />
            )}
        </Box>
    )
}
