import { useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useNavigationState() {
    const { workspaceNavState } = useNavigationContext()

    return useMemo(
        () => ({
            workspaceNavState,
        }),
        [workspaceNavState]
    )
}
