import React from 'react'

import { getWorkspaceUrl, Urls } from 'app/UrlService'

import { Box } from 'ui/components/Box'

import { useSystemNavigationState } from './hooks/useSystemNavigationState'
import * as Parts from './Navigation.parts'
import { SystemNavigationItem } from './SystemNavigationItem'
import { SystemNavigationItemNotifications } from './SystemNavigationItemNotifications'
import { SystemNavigationItemSearch } from './SystemNavigationItemSearch'

type SystemNavigationProps = {
    isCollapsed?: boolean
}

export const SystemNavigation: React.FC<SystemNavigationProps> = ({ isCollapsed }) => {
    const { showSearch, showNotifications, showTasks } = useSystemNavigationState()

    return (
        <Box flex column alignSelf="stretch" position="relative" style={{ zIndex: 1 }}>
            {showSearch && (
                <Box flex px="xs" alignSelf="stretch">
                    <SystemNavigationItemSearch isCollapsed={isCollapsed} />
                </Box>
            )}
            <Parts.SystemNavigationWrapper
                isCollapsed={isCollapsed}
                mt={showSearch && isCollapsed ? 'm' : 'xl'}
            >
                <SystemNavigationItem
                    iconName="home-02"
                    label="Home"
                    to={getWorkspaceUrl(Urls.Home)}
                    isCollapsed={isCollapsed}
                    aria-label="Home"
                />
                {showNotifications && (
                    <SystemNavigationItemNotifications isCollapsed={isCollapsed} />
                )}
                {showTasks && (
                    <SystemNavigationItem
                        iconName="checkmark-square-02"
                        label="Tasks"
                        to={getWorkspaceUrl(Urls.Tasks)}
                        isCollapsed={isCollapsed}
                        aria-label="Tasks"
                    />
                )}
            </Parts.SystemNavigationWrapper>
        </Box>
    )
}
