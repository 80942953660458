import React, { forwardRef, useMemo, useState } from 'react'

import { composeRefs } from '@radix-ui/react-compose-refs'

import { Field } from 'ui/components/Field'
import { Icon } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { makeComponent } from 'ui/helpers/recipes'
import { extractStandardProps } from 'ui/helpers/styles'
import { generateUniqueId } from 'ui/helpers/utilities'

import { EndActionProps, StartActionProps } from './types'

import { InputStyles, InputVariants, RightSlotContentStyles, RootStyles } from './Input.css'

const Root = makeComponent('div', RootStyles)
const InputBase = makeComponent('input', InputStyles)
const RightSlot = makeComponent('div', RightSlotContentStyles)

type LinkProps = {
    href?: string
    to?: string
    label: string
    target?: string
}

type InputRef = HTMLInputElement

type InputProps = Omit<React.ComponentPropsWithoutRef<typeof InputBase>, 'size'> &
    React.ComponentPropsWithoutRef<typeof Field> &
    InputVariants & {
        link?: LinkProps
        startAction?: React.ElementType<StartActionProps>
        endAction?: React.ElementType<EndActionProps>
        inputProps?: React.ComponentPropsWithoutRef<typeof InputBase>
    }

export const Input = forwardRef<InputRef, InputProps>(
    (
        {
            id,
            isError,
            size = 'm',
            readOnly,
            disabled,
            label,
            infoText,
            helperText,
            required,
            link,
            optional,
            startAction: StartAction,
            endAction: EndAction,
            type = 'text',
            variant = 'default',
            inputProps,
            ...props
        },
        ref
    ) => {
        // this is so the id property can be optional, but still use the
        // label htmlFor property to link the label to the input.
        const effectiveId = useMemo(() => id || generateUniqueId(), [id])

        const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)

        const [standardProps, { style, className, ...rootProps }] = extractStandardProps(props)

        return (
            <Field
                htmlFor={effectiveId}
                label={label}
                isError={isError}
                infoText={infoText}
                helperText={helperText}
                required={required}
                disabled={disabled}
                optional={optional}
                rightSlotContent={
                    link && (
                        <Link size="m" target={link.target} to={link.to} href={link.href}>
                            {link.label}
                        </Link>
                    )
                }
                {...standardProps}
            >
                <Root
                    size={size}
                    isError={isError}
                    isReadOnly={readOnly}
                    isDisabled={disabled}
                    variant={variant}
                    hasStartAction={!!StartAction}
                    hasEndAction={!!EndAction}
                    onPointerDown={(e) => {
                        const input = inputRef
                        if (!input) return

                        const target = e.target as HTMLElement
                        const closestInteractive = target.closest('button, a, input')

                        // Allow interacting with content in the right slot.
                        if (closestInteractive && closestInteractive !== input) {
                            e.stopPropagation()
                            e.preventDefault()
                            return
                        }

                        requestAnimationFrame(() => {
                            input?.focus()
                        })
                    }}
                    style={style}
                    className={className}
                >
                    {StartAction && (
                        <StartAction
                            inputType={type}
                            disabled={disabled ?? false}
                            readOnly={readOnly ?? false}
                            inputElement={inputRef}
                        />
                    )}
                    <InputBase
                        ref={composeRefs<HTMLInputElement>(setInputRef, ref)}
                        spellCheck={false}
                        disabled={disabled}
                        aria-invalid={isError || undefined}
                        readOnly={readOnly}
                        id={effectiveId}
                        required={required}
                        type={type}
                        {...rootProps}
                        {...inputProps}
                    />
                    {(isError || EndAction) && (
                        <RightSlot>
                            {isError && (
                                <Icon
                                    role="presentation"
                                    size="s"
                                    name="AlertCircle"
                                    color="iconError"
                                />
                            )}
                            {EndAction && (
                                <EndAction
                                    inputType={type}
                                    disabled={disabled ?? false}
                                    readOnly={readOnly ?? false}
                                    inputElement={inputRef}
                                />
                            )}
                        </RightSlot>
                    )}
                </Root>
            </Field>
        )
    }
)
