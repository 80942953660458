import React from 'react'
import { Redirect } from 'react-router-dom'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'
import { GoogleLoginButton } from 'features/auth/new-stacker-auth/GoogleLoginButton'
import { useSignUpForm } from 'features/auth/new-stacker-auth/useSignUpForm'
import publicAsset from 'utils/publicAsset'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'
import { Input } from 'ui/components/Input'
import { Link } from 'ui/components/Link'
import { Body } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { OnboardingFrame } from './OnboardingFrame'

export function OnboardingSignupPage() {
    const { user } = useAuthContext()
    const buttonSize = useResponsiveValue({ mobile: 'm', tabletLarge: 'l' }) as 'm' | 'l'
    const { register, onSubmit, errors, errorMessage, isLoading } = useSignUpForm()

    if (user) {
        return <Redirect to={getUrl(Urls.Root)} />
    }
    return (
        <OnboardingFrame
            headline="Start building in seconds"
            title="Get started with Stacker"
            footerContents={
                <Box flex center column>
                    <Body size="s">
                        Already have an account? <Link to={Urls.Login}>Sign in</Link>
                    </Body>
                    <Body size="s" textAlign="center">
                        By signing up you agree to our{' '}
                        <Link to="https://www.stackerhq.com/legal/terms">Terms</Link> &&nbsp;
                        <Link to="https://www.stackerhq.com/legal/privacy">
                            Privacy&nbsp;Policy
                        </Link>
                    </Body>
                </Box>
            }
            previewContents={
                <Box flex column center gap="4xl">
                    <Body textAlign="center" weight="medium" size="l">
                        High-performing teams at <br />
                        companies worldwide build with Stacker
                    </Body>
                    <img src={publicAsset('/static/media/client-logo-strip.png')} />
                </Box>
            }
        >
            <Box flex column gap="xl">
                <Body size="l">
                    Stacker gives you the simplicity of a spreadsheet but the power of a development
                    team.
                </Body>
                <Box as="form" flex column gap="xl" onSubmit={onSubmit}>
                    <Input
                        label="Full name"
                        placeholder="Full name"
                        {...register('name', {
                            required: { value: true, message: 'Please enter your full name' },
                        })}
                        isError={errors?.name}
                        helperText={errors?.name?.message}
                        autoFocus
                    />
                    <Input
                        label="Email"
                        placeholder="Email address"
                        {...register('email', {
                            required: { value: true, message: 'Please enter your email' },
                        })}
                        isError={errors?.email}
                        helperText={errors?.email?.message}
                    />
                    <Input
                        label="Password"
                        placeholder="Password"
                        type="password"
                        {...register('password', {
                            required: {
                                value: true,
                                message: 'Please enter a password',
                            },
                        })}
                        isError={errors?.password}
                        helperText={errors?.password?.message}
                        mb="xs"
                    />
                    {errorMessage && (
                        <Box color="textError" fontSize="bodyM" alignSelf="center" mb="m">
                            {errorMessage}
                        </Box>
                    )}

                    <Button type="submit" isLoading={isLoading} size={buttonSize}>
                        Get started with email
                    </Button>
                </Box>
                <Box flex center>
                    <Divider />
                    <Body mx="m">or</Body>
                    <Divider />
                </Box>
                <GoogleLoginButton size={buttonSize}>Get started with Google</GoogleLoginButton>
            </Box>
        </OnboardingFrame>
    )
}
