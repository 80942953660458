import React, { useState } from 'react'

import { useTheme } from '@chakra-ui/react'

import settings from 'app/settings'
import { useCreateDataConnection } from 'data/hooks/dataConnections/useCreateDataConnection'
import { invalidateDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import {
    invalidateExtDataIntegrations,
    useExtDataIntegrations,
} from 'data/hooks/dataConnections/useExtDataIntegrations'
import { useExternalDatabases } from 'data/hooks/dataConnections/useExternalDatabases'
import { useExternalObjects } from 'data/hooks/dataConnections/useExternalObjects'
import { usePopulateUserInfoForExtConnection } from 'data/hooks/dataConnections/usePopulateUserInfoForExtConnection'
import Nango from 'features/workflows/Integrations/NangoClient'

import { Icon } from 'v2/ui'
import { Airtable } from 'v2/ui/svgs'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { DropdownItem } from 'ui/components/Dropdown'
import { Link } from 'ui/components/Link'
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'ui/components/Modal'
import { Select, SelectOption, SelectOptionComplex } from 'ui/components/Select'
import { Skeleton } from 'ui/components/Skeleton'
import { Body, Headline } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'
import { Toggle } from 'ui/components/Toggle'

const TRANSLATIONS: Record<ExternalIntegrationId, Record<string, string>> = {
    airtable: {
        Databases: 'Bases',
        databases: 'bases',
        database: 'base',
        tables: 'tables',
    },
} as const

const INTEGRATION_ID_TO_NAME: Record<ExternalIntegrationId, string> = {
    airtable: 'Airtable',
} as const

type ExternalAccountSelect = React.ComponentPropsWithoutRef<typeof Select> & {
    externalIntegrationId: ExternalIntegrationId
    onAddExternalAccount: () => void
}
const ExternalAccountSelect: React.FC<ExternalAccountSelect> = ({
    externalIntegrationId,
    value,
    onChange,
    onAddExternalAccount,
    ...props
}) => {
    const theme = useTheme()

    const { data: integrationsData, isLoading: _isLoading } = useExtDataIntegrations()
    const extConnections = integrationsData?.connections.filter(
        (connection) => connection.provider_id === externalIntegrationId
    )

    const IntegrationIcon = () => (
        <Box
            style={{
                backgroundColor: theme.colors.grey[100],
                padding: '8px',
                borderRadius: '6px',
            }}
        >
            <Icon height="16px" width="16px" style={{}} svg={<Airtable />} />
        </Box>
    )
    return (
        <Select
            placeholder="Select or connect account"
            onChange={onChange}
            value={value}
            {...props}
            isLoading={true}
        >
            {extConnections?.map((extConnection) => (
                <SelectOptionComplex
                    key={extConnection.id}
                    label={INTEGRATION_ID_TO_NAME[externalIntegrationId]}
                    leftSlotContent={() => <IntegrationIcon />}
                    value={extConnection.id}
                    subtitle={extConnection.external_user_email}
                />
            ))}
            <DropdownItem
                label={`Connect ${INTEGRATION_ID_TO_NAME[externalIntegrationId]} account`}
                startIcon={{ name: 'Plus' }}
                onClick={onAddExternalAccount}
                variant="action"
            />
        </Select>
    )
}

type ExternalDatabaseSelectProps = React.ComponentPropsWithoutRef<typeof Select> & {
    externalIntegrationId: ExternalIntegrationId
    nangoConnectionId: string
    onAddExternalDatabase: () => void
    isDisabled: boolean
}

// This component is used to select which specific "database" user wants to import form external source (e.g. in
// airtable case a base is a database)
const ExternalDatabaseSelect: React.FC<ExternalDatabaseSelectProps> = ({
    externalIntegrationId,
    nangoConnectionId,
    value,
    onChange,
    onAddExternalDatabase,
    isDisabled,
    ...props
}) => {
    const { data: externalDatabases } = useExternalDatabases(
        { integrationId: externalIntegrationId, nangoConnectionId },
        {
            enabled: !!nangoConnectionId && !!externalIntegrationId,
        }
    )

    return (
        <Select
            placeholder={`Select ${TRANSLATIONS[externalIntegrationId].databases} to sync`}
            onChange={onChange}
            value={value}
            {...props}
            disabled={isDisabled}
        >
            {externalDatabases?.map((extDatabase) => (
                <SelectOption
                    key={extDatabase.id}
                    label={extDatabase.name}
                    startIcon={{ name: 'Database' }}
                    value={extDatabase.id}
                />
            ))}
            <DropdownItem
                label={`Add ${INTEGRATION_ID_TO_NAME[externalIntegrationId]} ${TRANSLATIONS[externalIntegrationId].database}`}
                startIcon={{ name: 'Plus' }}
                onClick={onAddExternalDatabase}
                variant="action"
            />
        </Select>
    )
}

type ExternalObjectSelectProps = React.ComponentPropsWithoutRef<typeof Select> & {
    externalIntegrationId: ExternalIntegrationId
    nangoConnectionId: string
    externalDatabaseId: string
    selectedExternalObjectIds: Set<string>
    setSelectedExternalObjectIds: (
        update: Set<string> | ((prevSelectedObjects: Set<string>) => Set<string>)
    ) => void
}
const ExternalObjectSelect: React.FC<ExternalObjectSelectProps> = ({
    externalIntegrationId,
    nangoConnectionId,
    externalDatabaseId,
    selectedExternalObjectIds,
    setSelectedExternalObjectIds,
}) => {
    const { data: externalObjects, isLoading } = useExternalObjects(
        { integrationId: externalIntegrationId, nangoConnectionId, externalDatabaseId },
        {
            enabled: !!nangoConnectionId && !!externalIntegrationId && !!externalDatabaseId,
            // if a different database is shown we want to go into a loading state and stop showing stale data immediately
            keepPreviousData: false,
        }
    )

    const handleToggleChange = (extObjectId: string) => {
        setSelectedExternalObjectIds((prevSelectedExtObjs) => {
            const newSelectedExtObjs = new Set(prevSelectedExtObjs)
            if (newSelectedExtObjs.has(extObjectId)) {
                newSelectedExtObjs.delete(extObjectId)
            } else {
                newSelectedExtObjs.add(extObjectId)
            }
            return newSelectedExtObjs
        })
    }

    const selectAll = () => {
        setSelectedExternalObjectIds(new Set(externalObjects?.map((obj) => obj.id)))
    }

    const deselectAll = () => {
        setSelectedExternalObjectIds(new Set())
    }

    return (
        <Box pt="3xl" flex flexDirection="column">
            <Box flex justifyContent="space-between">
                <Headline size="xs">
                    Choose {TRANSLATIONS[externalIntegrationId].tables} to include
                </Headline>
                <Box>
                    <Link onClick={selectAll}>select all</Link> /{' '}
                    <Link onClick={deselectAll}>deselect all</Link>
                </Box>
            </Box>
            <Body size="m" pt="m" pb="xl">
                Select the {TRANSLATIONS[externalIntegrationId].tables} you want to sync
            </Body>

            {isLoading ? (
                <>
                    <Skeleton height="4xl" mb="s" isLoading={true}>
                        <Box />
                    </Skeleton>
                    <Skeleton height="4xl" mb="s" isLoading={true}>
                        <Box />
                    </Skeleton>
                    <Skeleton height="4xl" mb="s" isLoading={true}>
                        <Box />
                    </Skeleton>
                </>
            ) : (
                externalObjects?.map((extObject) => (
                    <Box key={extObject.id} pt="m">
                        <Toggle
                            checked={selectedExternalObjectIds.has(extObject.id)}
                            onCheckedChange={() => handleToggleChange(extObject.id)}
                        >
                            {extObject.name}
                        </Toggle>
                    </Box>
                ))
            )}
        </Box>
    )
}

type NewDataConnectionsModalProps = {
    isOpen: boolean
    onClose: () => void
    toggle: () => void
}

export const NewDataConnectionsModal: React.FC<NewDataConnectionsModalProps> = ({
    isOpen,
    onClose,
    toggle,
}) => {
    // the external account to create new data connection for (the value will be the nango connection id)
    const [selectedExternalAccountId, setSelectedExternalAccountId] = useState<string>('')
    const [selectedExternalDatabaseId, setSelectedExternalDatabaseId] = useState<string>('')
    const [selectedExternalObjectIds, setSelectedExternalObjectIds] = useState<Set<string>>(
        new Set()
    )

    const { data: integrationsData, isLoading: _isLoading } = useExtDataIntegrations()

    const toast = useToast()
    const { mutateAsync: createDataConnection } = useCreateDataConnection({
        onError: () => {
            toast({
                title: 'There was a problem creating the data connection. Please try again later.',
                type: 'error',
            })
        },
    })

    const { mutateAsync: populateUserInfoInConnection } = usePopulateUserInfoForExtConnection({
        onError: () => {
            toast({
                // TODO-Nango: REMOVE THIS, only for dev
                title: 'There was a problem populating user info for the connection created',
                type: 'error',
            })
        },
    })

    const addNewExternalAccount = async () => {
        try {
            if (!integrationsData?.nango_connection_id) {
                throw new Error('Connection ID not set for provider.')
            }

            const nango = new Nango({ publicKey: settings.NANGO_KEY })

            // TODO-Nango: replace airtable with integration.id or some other prop passed in
            await nango.auth('airtable', integrationsData.nango_connection_id, {
                params: {},
                // TODO-Nango: Remove assumption that airtable is the only and first integrations
                hmac: integrationsData.integrations[0].hmac,
            })
            await populateUserInfoInConnection({
                nangoConnectionId: integrationsData.nango_connection_id,
                integrationId: 'airtable',
            })
            await invalidateExtDataIntegrations()

            toast({ title: 'Connection added successfully.', type: 'success' })
        } catch (err) {
            console.log('# auth error:', err)

            toast({
                title: 'There was a problem authenticating your connection. Please try again later.',
                type: 'error',
            })
        }
    }

    return (
        <Modal open={isOpen} onOpenChange={toggle}>
            <ModalContent>
                <ModalHeader title="Add data source" showCloseButton={true} />
                <Box pb="xl" px="3xl">
                    <Box pb="s">
                        <Body size="m" weight="bold" paddingBottom="m">
                            Account
                        </Body>
                    </Box>
                    <ExternalAccountSelect
                        externalIntegrationId="airtable"
                        value={selectedExternalAccountId}
                        onChange={setSelectedExternalAccountId}
                        onAddExternalAccount={addNewExternalAccount}
                    />
                </Box>

                <Box pb="xl" px="3xl">
                    <Box pb="s">
                        <Body size="m" weight="bold" paddingBottom="m">
                            {TRANSLATIONS['airtable'].Databases}
                        </Body>
                    </Box>
                    <ExternalDatabaseSelect
                        externalIntegrationId="airtable"
                        nangoConnectionId={selectedExternalAccountId}
                        value={selectedExternalDatabaseId}
                        onChange={setSelectedExternalDatabaseId}
                        isDisabled={!selectedExternalAccountId}
                        onAddExternalDatabase={() => {
                            // TODO-Nango
                            console.log('*** onAddExternalDatabase called')
                        }}
                    />
                </Box>

                {!!selectedExternalAccountId && !!selectedExternalDatabaseId && (
                    <Box pb="xl" px="3xl">
                        <ExternalObjectSelect
                            externalIntegrationId="airtable"
                            nangoConnectionId={selectedExternalAccountId}
                            externalDatabaseId={selectedExternalDatabaseId}
                            selectedExternalObjectIds={selectedExternalObjectIds}
                            setSelectedExternalObjectIds={setSelectedExternalObjectIds}
                        />
                    </Box>
                )}

                <ModalFooter flex flexDirection="row" style={{ justifyContent: 'flex-end' }}>
                    <Button size="l" variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        size="l"
                        variant="primary"
                        onClick={async () => {
                            await createDataConnection({
                                label: selectedExternalAccountId, // TODO-Nango: replace with a better label (unsure what though)
                                type: 'nango_airtable',
                                nango_connection_id: selectedExternalAccountId,
                                external_database_id: selectedExternalDatabaseId,
                                external_object_ids: Array.from(selectedExternalObjectIds),
                            })
                            invalidateDataConnections()
                            onClose()
                        }}
                        disabled={
                            !selectedExternalAccountId ||
                            !selectedExternalDatabaseId ||
                            !selectedExternalObjectIds.size
                        }
                    >
                        Continue
                    </Button>
                    {/*TODO-Nango capture and display errors */}
                    {/*{error && (*/}
                    {/*    <Body color="textError" size="s" mt="m" weight="medium">*/}
                    {/*        {error}*/}
                    {/*    </Body>*/}
                    {/*)}*/}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
