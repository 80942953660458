import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Rights } from 'app/accountUserContextConstants'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { useAccountUserContext } from 'app/useAccountUserContext'
import { useAppContext } from 'app/useAppContext'
import { useAppUserContext } from 'app/useAppUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { useAccounts } from 'data/hooks/accounts'
import { useGroupedWorkspaceStacks } from 'data/hooks/stacks'
import { useZones } from 'data/hooks/zones'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { NavigationContext, NavigationContextValue } from './NavigationContext'
import {
    NavigationCreateAppModal,
    NavigationCreateAppModalHandle,
} from './NavigationCreateAppModal'
import {
    NavigationCreateViewModal,
    NavigationCreateViewModalHandle,
} from './NavigationCreateViewModal'
import { NavigationApp } from './types'
import {
    getNavigationCurrentUserFromUser,
    mapGroupsToNavigationSpaces,
    mapPortalsToNavigationPortals,
    mapStackToNavigationApp,
} from './utils'

type NavigationContextProviderProps = {}

export const NavigationContextProvider: React.FC<NavigationContextProviderProps> = ({
    children,
}) => {
    const { value, createViewModalRef, createAppModalRef } = useContextValue()

    return (
        <NavigationContext.Provider value={value}>
            {children}
            {!!value.workspaceZone && !!value.workspaceAccount && (
                <>
                    <NavigationCreateViewModal ref={createViewModalRef} />
                    <NavigationCreateAppModal ref={createAppModalRef} />
                </>
            )}
        </NavigationContext.Provider>
    )
}

function useContextValue() {
    const [workspaceNavState, setWorkspaceNavState] = useState<'static' | 'collapsed' | 'expanded'>(
        'collapsed'
    )

    const tryCollapseWorkspaceNav = useCallback(() => {
        setWorkspaceNavState((prev) => {
            if (prev === 'expanded') {
                return 'collapsed'
            }

            return prev
        })
    }, [])

    const { workspaceZone, isOnPortalDomain, workspaceAccount } = useWorkspaceContext()
    const workspaceAccountMemo = useDeepEqualsMemoValue(workspaceAccount ?? undefined)
    const workspaceZoneMemo = useDeepEqualsMemoValue(workspaceZone ?? undefined)

    const { hasRight, role: workspaceAccountRole } = useAccountUserContext()
    const canEditWorkspaceSettings = hasRight(Rights.ViewSettings)
    const canEditPortalSettings = hasRight(Rights.ViewSettings)
    const canCreatePortal = hasRight(Rights.ManageSettings)
    const canCreateApp = hasRight(Rights.CreateApps)

    const { isAdmin } = useAppUserContext()

    const { data: workspaceAccounts = [] } = useAccounts()
    const { data: workspaceZones = [] } = useZones()

    const internalZone = workspaceZones.find(
        (zone) => zone.account_sid === workspaceAccountMemo?._sid && zone.type === 'Internal'
    )

    const { selectedStack } = useAppContext()
    const selectedApp = selectedStack
        ? mapStackToNavigationApp({
              stack: selectedStack,
              account: workspaceAccountMemo,
              zone: internalZone,
              isOnPortalDomain,
          })
        : undefined
    const selectedAppMemo = useDeepEqualsMemoValue(selectedApp)
    const selectedAppRef = useRef(selectedApp)
    selectedAppRef.current = selectedApp

    const [mainNavState, setMainNavState] = useState<'static' | 'collapsed'>('collapsed')
    useLayoutEffect(() => {
        if (selectedAppMemo) {
            setWorkspaceNavState('collapsed')
            setMainNavState('static')
        } else {
            // If there is no selected app, we want to expand the workspace nav and collapse the app nav.
            setWorkspaceNavState('static')
            setMainNavState('collapsed')
        }
    }, [selectedAppMemo])

    const { data: groups } = useGroupedWorkspaceStacks({ filterByZoneSid: internalZone?._sid })
    const spaces = useDeepEqualsMemoValue(
        mapGroupsToNavigationSpaces({
            groups,
            account: workspaceAccountMemo,
            zone: internalZone,
            isOnPortalDomain,
        })
    )

    const history = useHistory()
    const executeActionInApp = useCallback(
        (app: NavigationApp, action: () => void) => {
            tryCollapseWorkspaceNav()

            const selectedApp = selectedAppRef.current
            // Go to the app if we're not already on it.
            if (selectedApp?.id !== app.id) {
                history.push(app.url)
            }

            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    action()
                })
            })
        },
        [tryCollapseWorkspaceNav, history]
    )

    const createViewModalRef = useRef<NavigationCreateViewModalHandle>(null)

    const showCreateViewModal = useCallback(
        (app: NavigationApp, display: ListViewDisplay) => {
            executeActionInApp(app, () => {
                createViewModalRef.current?.open(display)
            })
        },
        [executeActionInApp]
    )

    const { user } = useAuthContext()
    const currentUser = useDeepEqualsMemoValue(getNavigationCurrentUserFromUser(user))

    const createAppModalRef = useRef<NavigationCreateAppModalHandle>(null)

    const showCreateAppModal = useCallback(
        (spaceId?: string) => {
            createAppModalRef.current?.open(spaceId)
        },
        [createAppModalRef]
    )

    const isPortal = workspaceZoneMemo?.type === 'Portal'

    const portals = useDeepEqualsMemoValue(
        mapPortalsToNavigationPortals({
            zones: workspaceZones,
            account: workspaceAccountMemo,
            isOnPortalDomain,
        })
    )

    const permissions: NavigationContextValue['permissions'] = useMemo(
        () => ({
            canEditWorkspaceSettings,
            canEditPortalSettings,
            canDeleteApp: isAdmin,
            canEditAppSettings: isAdmin,
            canEditAppUsers: isAdmin,
            canPinApp: isAdmin,
            canCreateTable: isAdmin,
            canCreateView: isAdmin,
            canCreateCustomPage: isAdmin,
            canDeleteLayout: isAdmin,
            canEditViewLayout: isAdmin,
            canDuplicateLayout: isAdmin,
            canArchiveApp: isAdmin,
            canChangeAppSpace: isAdmin,
            canCreateApp,
            canCreatePortal,
        }),
        [canEditWorkspaceSettings, canEditPortalSettings, isAdmin, canCreateApp, canCreatePortal]
    )

    const value = useDeepEqualsMemoValue({
        workspaceNavState,
        setWorkspaceNavState,
        tryCollapseWorkspaceNav,
        executeActionInApp,
        mainNavState,
        setMainNavState,
        workspaceZone: workspaceZoneMemo,
        permissions,
        selectedApp: selectedAppMemo,
        currentUser,
        spaces,
        isPortal,
        isOnPortalDomain,
        showCreateViewModal,
        workspaceAccountRole,
        workspaceAccounts,
        workspaceAccount: workspaceAccountMemo,
        workspaceZones,
        showCreateAppModal,
        portals,
    })

    return useMemo(() => ({ value, createViewModalRef, createAppModalRef }), [value])
}
