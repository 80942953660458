import React from 'react'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'

if (process.env.NODE_ENV === 'development' && false) {
    // eslint-disable-next-line no-undef
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    })
}

if (process.env.NODE_ENV === 'development' && false) {
    // eslint-disable-next-line no-undef
    const { registerObserver } = require('react-perf-devtool')
    registerObserver()
}

// don't rebind on hot reload
let keyboardShortcutsBound = false

// handy keyboard shortcuts for dev & staging
if (!settings.IS_PROD) {
    // ctrl (or cmd on mac) + letter key calls the function
    const shortcuts = {
        // open stack settings
        g: () => {
            // open in a new tab
            window.open(getUrl(Urls.StackSettings), '_blank')
        },
        // open account settings
        h: () => {
            // open in a new tab
            window.open('/__account', '_blank')
        },
    }

    if (!keyboardShortcutsBound) {
        document.addEventListener('keydown', (event) => {
            if (event.ctrlKey || event.metaKey) {
                return shortcuts[event.key]?.()
            }
        })
        keyboardShortcutsBound = true
    }
}

// temporary warning to not use V3 version of backstage!
if (!settings.IS_PROD && window.location.hostname === 'backstage.my.stacker.dev') {
    const ok = window.confirm(
        '\n👋 Hey there\n\nPlease use the V4 version of Backstage! 😄\n\nOK => redirect to V4\nCancel => continue on V3'
    )

    if (ok) {
        window.location.href = 'https://stacker.my.stacker.dev/backstage' + window.location.pathname
    }
}
