import { useCallback, useMemo, useRef } from 'react'

import { getAbsoluteUrl } from 'app/UrlService'
import { useFavorite } from 'data/hooks/favorites/favorites'
import { FavoriteType } from 'data/hooks/favorites/types'
import { useUpdateStackOptions } from 'data/hooks/stacks'
import useLDFlags from 'data/hooks/useLDFlags'
import { NavigationApp } from 'features/navigation/types'
import { useCopyLink } from 'features/utils/useCopyLink'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { useNavigationContext } from './useNavigationContext'

type UseWorkspaceNavigationAppDropdownStateProps = {
    app: NavigationApp
}

export function useWorkspaceNavigationAppDropdownState({
    app,
}: UseWorkspaceNavigationAppDropdownStateProps) {
    const { permissions, executeActionInApp, spaces } = useNavigationContext()

    const { showAppSettings: showAppSettingsPane, showManageUsers: showManageUsersPane } =
        useSlidingPane()

    const canShowAppSettings = permissions.canEditAppSettings
    const showAppSettings = useCallback(() => {
        executeActionInApp(app, showAppSettingsPane)
    }, [executeActionInApp, showAppSettingsPane, app])

    const canShowAppUsers = permissions.canEditAppUsers
    const showAppUsers = useCallback(() => {
        executeActionInApp(app, showManageUsersPane)
    }, [executeActionInApp, showManageUsersPane, app])

    const canDeleteApp = permissions.canDeleteApp
    const deleteApp = useCallback(() => {
        executeActionInApp(app, () => {
            showAppSettingsPane({ page: { name: 'general' } })
        })
    }, [executeActionInApp, showAppSettingsPane, app])

    const stackRef = useRef(app.stack)
    stackRef.current = app.stack
    const updateStack = useUpdateStackOptions(true)

    const isPinned = !!app.stack.options.pin_to_app_bar
    const isPinnedRef = useRef(isPinned)
    isPinnedRef.current = isPinned
    const canTogglePin = permissions.canPinApp
    const togglePin = useCallback(() => {
        requestAnimationFrame(() => {
            const isPinned = isPinnedRef.current
            updateStack(stackRef.current, { pin_to_app_bar: !isPinned })
        })
    }, [updateStack])

    const absoluteUrl = getAbsoluteUrl(app.url)
    const executeCopy = useCopyLink()
    const copyLink = useCallback(async () => {
        return executeCopy(absoluteUrl)
    }, [absoluteUrl, executeCopy])

    const { flags } = useLDFlags()

    const canFavoriteApp = !!flags.favorites
    const { favorite, toggleFavorite: toggleFavoriteApp } = useFavorite({
        targetType: FavoriteType.App,
        stackId: app.stack._sid,
    })
    const isFavorite = !!favorite
    const toggleFavorite = useCallback(() => {
        requestAnimationFrame(() => {
            toggleFavoriteApp()
        })
    }, [toggleFavoriteApp])

    const canArchiveApp = permissions.canArchiveApp
    const isArchived = !!app.stack.options.is_archived
    const isArchivedRef = useRef(isArchived)
    isArchivedRef.current = isArchived
    const toggleArchiveApp = useCallback(() => {
        requestAnimationFrame(() => {
            const isArchived = isArchivedRef.current

            const patch: Partial<StackDto['options']> = {
                is_archived: !isArchived,
            }
            // Unpin the app if it's being archived.
            if (!isArchived) {
                patch.pin_to_app_bar = false
            }

            updateStack(stackRef.current, patch)
        })
    }, [updateStack])

    const canMoveToSpace = permissions.canChangeAppSpace
    const moveToSpace = useCallback(
        (spaceId: string) => {
            requestAnimationFrame(() => {
                updateStack(stackRef.current, { group: spaceId })
            })
        },
        [updateStack]
    )

    return useMemo(
        () => ({
            canShowAppSettings,
            showAppSettings,
            canShowAppUsers,
            showAppUsers,
            canDeleteApp,
            deleteApp,
            canTogglePin,
            togglePin,
            isPinned,
            copyLink,
            canFavoriteApp,
            isFavorite,
            toggleFavorite,
            canArchiveApp,
            toggleArchiveApp,
            isArchived,
            canMoveToSpace,
            moveToSpace,
            spaces,
        }),
        [
            canShowAppSettings,
            showAppSettings,
            canShowAppUsers,
            showAppUsers,
            canDeleteApp,
            deleteApp,
            canTogglePin,
            togglePin,
            isPinned,
            copyLink,
            canFavoriteApp,
            isFavorite,
            toggleFavorite,
            canArchiveApp,
            toggleArchiveApp,
            isArchived,
            canMoveToSpace,
            moveToSpace,
            spaces,
        ]
    )
}
