import React from 'react'

import { useAppNavigationState } from './hooks/useAppNavigationState'
import { AppNavigationContextProvider } from './AppNavigationContextProvider'
import { AppNavigationHeader } from './AppNavigationHeader'
import { AppNavigationMenu } from './AppNavigationMenu'

type AppNavigationProps = {}

export const AppNavigation: React.FC<AppNavigationProps> = () => {
    const { selectedApp } = useAppNavigationState()

    if (!selectedApp) return null

    return (
        <AppNavigationContextProvider app={selectedApp}>
            <AppNavigationHeader />
            <AppNavigationMenu />
        </AppNavigationContextProvider>
    )
}
