import React from 'react'

import { ScrollArea } from 'ui/components/ScrollArea'

import { AppNavigationTree } from './AppNavigationTree'

type AppNavigationMenuProps = {}

export const AppNavigationMenu: React.FC<AppNavigationMenuProps> = () => {
    return (
        <ScrollArea px="m" pb="xl" direction="vertical">
            <AppNavigationTree />
        </ScrollArea>
    )
}
