import { useCallback, useLayoutEffect, useMemo, useRef } from 'react'

import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'

import { useNavigationContext } from './useNavigationContext'

export function useWorkspaceNavigationState() {
    const { permissions, isPortal, selectedApp } = useNavigationContext()

    const { workspaceNavState, setWorkspaceNavState, tryCollapseWorkspaceNav } =
        useNavigationContext()

    const isWorkspaceNavCollapsed = workspaceNavState === 'collapsed'

    const toggleWorkspaceNavCollapsed = useCallback(() => {
        setWorkspaceNavState((prevState) => (prevState === 'collapsed' ? 'expanded' : 'collapsed'))
    }, [setWorkspaceNavState])

    const tooltipText = isWorkspaceNavCollapsed ? 'Expand navigation' : 'Collapse navigation'

    const navWrapperRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (workspaceNavState !== 'expanded') return

        // Close on click outside the navigation.
        const handleClickOutside = (event: MouseEvent) => {
            const navWrapper = navWrapperRef.current
            if (!navWrapper) return

            const targetEl = event.target as HTMLElement | null
            if (!targetEl) return

            // If the click target is inside a popup, don't close the nav.

            const isRadixPopperOpen = document.querySelector('[data-radix-popper-content-wrapper]')
            if (isRadixPopperOpen) return

            const isAgGridPopupOpen = document.querySelector('.ag-custom-component-popup')
            if (isAgGridPopupOpen) return

            if (!navWrapper.contains(targetEl)) {
                tryCollapseWorkspaceNav()
            }
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [tryCollapseWorkspaceNav, workspaceNavState])

    const canEditWorkspaceSettings = permissions.canEditWorkspaceSettings && !isPortal

    const handleOpenWorkspaceSettings = useCallback(() => {
        tryCollapseWorkspaceNav()
        openWorkspaceSettingsModal({ page: 'general' })
    }, [tryCollapseWorkspaceNav])

    const hasAppSelected = !!selectedApp
    return useMemo(
        () => ({
            workspaceNavState,
            navWrapperRef,
            isWorkspaceNavCollapsed,
            toggleWorkspaceNavCollapsed,
            tooltipText,
            handleOpenWorkspaceSettings,
            canEditWorkspaceSettings,
            hasAppSelected,
        }),
        [
            workspaceNavState,
            isWorkspaceNavCollapsed,
            toggleWorkspaceNavCollapsed,
            tooltipText,
            handleOpenWorkspaceSettings,
            canEditWorkspaceSettings,
            hasAppSelected,
        ]
    )
}
