import { useCallback } from 'react'

import { useOmnibarContext } from 'features/Omnibar/OmnibarContext'

import { useKeyboardShortcutLabel } from 'ui/helpers/useKeyboardShortcutLabel'

import { useNavigationContext } from './useNavigationContext'

export function useSystemNavigationItemSearchState() {
    const { tryCollapseWorkspaceNav } = useNavigationContext()

    const { setIsOpen: setIsOmnibarOpen } = useOmnibarContext()

    const onClick = useCallback(() => {
        setIsOmnibarOpen(true)
        tryCollapseWorkspaceNav()
    }, [setIsOmnibarOpen, tryCollapseWorkspaceNav])

    const keyboardShortcutLabel = useKeyboardShortcutLabel({
        mac: '⌘K',
        default: 'Ctrl+K',
    })

    return {
        onClick,
        keyboardShortcutLabel,
    }
}
